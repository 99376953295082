import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LeftNavBar } from '../components/leftNavBar';

export const Service = () => {
    return (
        <Row>
            <LeftNavBar />
            <Col>
                <h2 style={{ fontWeight: 700, padding: 10 }}>
                Pump Service and Spare Parts
                </h2>
                <p>Pump Application Engineers provides a complete service to our clients including a support service for ongoing maintenance requirements and/or the necessary spare parts irrespective of brand or type.</p>
                <p>If you don’t have your own onsite maintenance staff we are able to arrange servicing of your pump equipment to be undertaken either onsite or off site if it is more practicable.</p>
                <p>Do you have the spares listing of your pumps?</p>
                <p>Do you experience problems ordering spare parts?</p>
                <p>Do you experience delays in procuring spare parts?</p>
                <p style={{paddingTop: 10}}>A Site Services Survey from Pump Application Engineers will ensure that your operation and service manuals and spare parts lists are current and on file. To enquire further on the services we offer please contact us.</p>
            </Col>
        </Row>
    )
}