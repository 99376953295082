import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LeftNavBar } from '../components/leftNavBar';

export const AboutUs = () => {
    return (
        <Row>
            <LeftNavBar />
            <Col>
                <h2 style={{fontWeight: 700, padding: 10}}>
                    About Pump Application Engineers
                </h2>
                <p>Pump Application Engineers recently commenced operating in August 2009. Whilst we are a new start up company, we have an extensive history within the industrial pump market. Our executive director Greg Riding is industry trained and has undertaken a diverse range of roles including system design, selection and application, installation, commissioning and maintenance of pumping systems for over 33 years.</p>
                <p>With a vast knowledge of pump design and applications within the potable water, waste water, food processing, building and contracting services, general industry, mining, irrigation, fire and mechanical services industries, Greg has undertaken numerous projects for clients throughout eastern Australia. His approachability and thoroughness instils client confidence and his track record is testimony to his broad understanding of the industrial pump market.</p>
                <p>As a new company our market direction is geographically based, our focus being the rapidly developing south east Queensland industrial pump market. Brisbane based Pump Application Engineers is well placed to service this market.</p>
                <p>Pump Application Engineers strongly believes in providing its customers with a high standard of service and ensuring the final outcome meets the client’s objectives. From the initial inquiry and consultation stage, through to the design and commissioning stages our objective is to provide a comprehensive solution. At the project’s inception we seek to understand all of the issues that will need to be addressed with particular emphasis on the overall process stream.</p>
                <p>Being an independent provider of pumping equipment we only select the most appropriate equipment for the application from a large range of manufacturers’ products. Our philosophy and our long-term experience confirm to us that the correct application of a pump is essential to not only ensure the ongoing reliability of the pump itself but more importantly to ensure the pump does not adversely affect the process stream it works within. “All we provide are solutions” is what we strive to achieve and our promise to provide our clients with outstanding service.</p>
            </Col>
        </Row>
    )
}