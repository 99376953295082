import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { LeftNavBar } from '../components/leftNavBar';
import ChemicalResistanceChart from '../pdf/chemical-resistance-chart-master.pdf'
import { UrlPaths } from '../components/urlPaths';
import { Link } from 'react-router-dom';

export const HomePage = () => {
  return (
    <Row>
      <LeftNavBar />
      <Col style={{ paddingBottom: 30 }}>
        <h1>Pump Application Engineers - Pumps Brisbane</h1>
        <h2 style={{ textAlign: 'right', color: '#0099cc' }}><em>"All we provide are solutions"</em></h2>
        <p>Brisbane based Pump Application Engineers is well placed to service the expanding industrial pump market in South East Queensland. With more than 30 years experience in the pump industry our services include design, installation, commissioning and maintenance of pump systems in the potable water, waste water, food processing, building and contracting services, mining, irrigation, fire and mechanical services industries.</p>
        <p>
          Staff are committed to complete customer service from initial enquiry and consultation through design and commission stages and also provide ongoing maintenance and repair work for pumps and their associated equipment.  Being an independent provider of pumping equipment we are able to select the most appropriate equipment for the application from a large range of manufacturers’ products.
        </p>
        <Row style={{ textAlign: 'center' }}>
          <Col md={4} style={{ paddingTop: 10 }} >
            <Button className='yellow_button home_button' href={ChemicalResistanceChart} download >Chemical Resistance Chart</Button>
          </Col>
          <Col md={4} style={{ paddingTop: 10 }} >
            <Button as={Link} to={UrlPaths.chemicallookup} className='yellow_button home_button' >Chemical Resistance Look up</Button>
          </Col>
          <Col md={4} style={{ paddingTop: 10 }}>
            <Button as={Link} to={UrlPaths.technicalinfo} className='yellow_button home_button' >Conversion Tools & Pump Formulae</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};