import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { UrlPaths } from './urlPaths';
import { Link } from 'react-router-dom'

export const LeftNavBar = () => {
    return (
      <Col sm={3} style={{ backgroundColor: '#cccccc', textAlign: 'center' }}>
        {
          [
            { link: UrlPaths.enquiryform, className: 'light_blue_button home_button', label: 'Request a Quote' },
            { link: UrlPaths.contactus, className: 'light_blue_button home_button', label: 'Online Enquiry' },
            { link: UrlPaths.services, className: 'dark_blue_button home_button', label: 'Services' },
            { link: UrlPaths.requestspareparts, className: 'dark_blue_button home_button', label: 'Products' },
          ].map((b, k) => <div key={k} style={{ padding: 5 }}><Button as={Link} to={b.link} className={b.className}>{b.label}</Button></div>)
        }
      </Col>
    )
  }
  