export const materialList = [
    'All Materials',
    '302 Stainless Steel',
    '304 Stainless Steel',
    '316 Stainless Steel',
    '440 Stainless Steel',
    'Aluminium',
    'Titanium',
    'Hastelloy C',
    'Cast Bronze',
    'Brass',
    'Cast Iron',
    'Carbon Steel',
    'Kynar',
    'PVC 00C to 600C',
    'Tygon -500C to 700C',
    'Teflon -290C to 1070C',
    'Noryl -400C to 1300C',
    'Polyacetal -280C to 820C',
    'Nylon -180C to 930C',
    'ABS -200C to 600C',
    'Polyethylene to 600C',
    'Polypropylene -200C to 900C',
    'Ryton 00C to 600C',
    'Viton -400C to 1760C',
    'Nitrile (Buna N) -120C to 820C',
    'Silicon -550C to 2000C',
    'Neoprene -180C to 830C',
    'EPDM to 900C',
    'Natural Rubber -550C to 550C',
    'Epoxy',
    'Carbon',
    'Ceramic',
    'Ceramagnet A to 220C'
]
