import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { LeftNavBar } from '../components/leftNavBar';
import PumpTermsandUnits from '../pdf/definition-of-pump-terms-and-units.pdf';
import Pumpformulae from '../pdf/pump-formulae.pdf';
import Pumptechnicalterminology from '../pdf/pump-technical-terminology.pdf';
import Glossaryofpumpengineeringterminology from '../pdf/glossary-of-pump-engineering-terminology.pdf';
import Pumpaffinitylaws from '../pdf/pump-affinity-laws.pdf';
import Pumpconversiontables from '../pdf/pump-conversion-tables-30-10-09.pdf';
import pumpselectionchecklist from '../pdf/pump-selection-checklist.pdf';


import PDFFile from '../images/pdfFile.png';


const pdfDownloads = [
    { label: 'Definition of Pump Terms and Units', href: PumpTermsandUnits },
    { label: 'Glossary of Pump Engineering Terminologys', href: Glossaryofpumpengineeringterminology },
    { label: 'Pump Affinity Laws', href: Pumpaffinitylaws },
    { label: 'Pump Conversion Tables', href: Pumpconversiontables },
    { label: 'Pump Formulae', href: Pumpformulae },
    { label: 'Pump Technical Terminology', href: Pumptechnicalterminology },
    { label: 'Pump Selection Checklist', href: pumpselectionchecklist },
]

export const TechnicalInfo = () => {
    return (
        <Row>
            <LeftNavBar />
            <Col  style={{paddingBottom: 30 }}>
                <h5 style={{ paddingTop: 10 }}>Click to download any required pdf files.</h5>
                {
                    pdfDownloads.map((p, k) => <p key={k}><Image src={PDFFile} style={{ maxHeight: 50 }} /><a href={p.href} target="_blank" rel="noreferrer" download>{p.label}</a></p>)
                }
            </Col>
        </Row>
    )
}