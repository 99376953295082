export const UrlPaths = {
    home: '/',
    aboutus: '/aboutus',
    customerservice: '/customerservice',
    sparesandrepairs: '/sparesandrepairs',
    technicalinfo: '/technicalinfo',
    chemicallookup: '/chemicallookup',
    contactus: '/contactus',
    enquiryform: '/enquiryform',
  
    services: '/services',
    requestspareparts: '/requestspareparts',
    requestrepair: '/requestrepair',

    form: '/forms'
  }