import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { HomePage, AboutUs, CustomerService, ContactUs, EnquiryForm, SparePartsRequest, ServiceRepair, TechnicalInfo, Service, ChemicalResistanceLookUp } from './pages/index';
import { UrlPaths } from './components/urlPaths';
import { PageLayout } from './PageLayout'

const App = () => {
  return (
    <PageLayout>
      <Routes>
        <Route index path={UrlPaths.home} element={<HomePage />} />
        <Route path={UrlPaths.aboutus} element={<AboutUs />} />
        <Route path={UrlPaths.customerservice} element={<CustomerService />} />
        <Route path={UrlPaths.contactus} element={<ContactUs />} />
        
        <Route path={UrlPaths.sparesandrepairs} element={<SparePartsRequest />} />
        <Route path={UrlPaths.technicalinfo} element={<TechnicalInfo />} />
        <Route path={UrlPaths.services} element={<Service />} />

        <Route path={UrlPaths.chemicallookup} element={<ChemicalResistanceLookUp />} />


        <Route path={UrlPaths.enquiryform} element={<EnquiryForm />} />
        <Route path={UrlPaths.requestspareparts} element={<SparePartsRequest />} />
        <Route path={UrlPaths.requestrepair} element={<ServiceRepair />} />
      </Routes>
    </PageLayout>
  )
}

export default App;
