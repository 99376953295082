import React, { Fragment, useState } from 'react';
import { Row, Col, Form, Table } from 'react-bootstrap';
import { LeftNavBar } from '../components/leftNavBar';
import { chemicalList } from '../data/chemicalList';
import { materialList } from '../data/materialList';
import { chemicalResult } from '../data/chemicalResult';

export const ChemicalResistanceLookUp = () => {
    const [chemical, setChemical] = useState(undefined);
    const [material, setMaterial] = useState(-1);

    const onChangeSetChemical = (value) => {
        const splitLabelAndValue = value.split('|');
        const result = splitLabelAndValue[1].split(',').map(t => parseInt(t));
        setChemical({ label: splitLabelAndValue[0], result: result })
    }

    return (
        <Row>
            <LeftNavBar />
            <Col style={{ padding: '20px 10px' }}>
                <h2>Chemical Resistance Lookup</h2>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="chems">Chemicals</Form.Label>
                                <Form.Select defaultValue={-1} onChange={(e) => onChangeSetChemical(e.target.value)} id="chems">
                                    {
                                        !chemical ? <option value={-1} key={-1} disabled>Please choose a chemical</option> : <Fragment />
                                    }
                                    {
                                        chemicalList.map((c, k) => <option key={k} value={`${c.label}|${c.result}`}>{c.label}</option>)
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="mats">Material</Form.Label>
                                <Form.Select defaultValue={-1} onChange={(e) => setMaterial(parseInt(e.target.value))} id="mats">
                                    {
                                        material === -1 ? <option key={-1} value={-1} disabled>Please choose a material</option> : <Fragment />
                                    }
                                    {
                                        materialList.map((c, k) => <option key={k} value={k}>{c}</option>)
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div>
                    <LookupResult chemical={chemical} material={material} result={GetResults(material, chemical)} />
                </div>
            </Col>

        </Row>
    );
};

const GetResults = (material, chemical) => {
    if (material === 0) {
        return materialList.map((m, k) => {
            return (<tr>
                <td>{m}</td>
                <td>{chemicalResult[chemical.result[k]]}</td>
            </tr>)
        });
    }

    return (
        <tr>
            <td>{materialList[material]}</td>
            <td>{chemicalResult[chemical?.result.at(material)]}</td>
        </tr>
    )
}


const LookupResult = ({ chemical, material, result }) => {
    if (material === -1) { return <Fragment />; }
    if (!chemical) { return <Fragment />; }
    return (
        <Fragment>
            <hr />
            <h3>Chemical selected: {chemical.label}</h3>
            <p><strong>Please Note:</strong><br />
                This chart has been compiled from a multiple of sources and whilst all are believed to be reliable, the information contained within cannot be guaranteed.<br />
                This publication is only intended to be used a general guide and Pump Application Engineers Pty Ltd assumes no liability for the accuracy or completeness of the information contained within.<br />
                The table includes a liquid list most commonly found in the chemical, food processing, general industrial and paint industries.<br />
                Due to the complex nature of Chemical Compatibility, this is not an comprehensive listing.<br />
                Other factors that effect chemical compatibility are temperature, concentration of the liquid and the impurities present.<br />
                Another factor to be considered is the operation of the pumping system as this may have a long term effect on the liquid being pumped and the rate of corrosion present.<br />
                These notes are to be read for all subsequent pages listed in this chart.
            </p>
            <div style={{ fontWeight: 500 }}>Ratings: 
                0 - No Available Data |
                1 - No Effect (Excellent) | 
                2 - Minor Effect (Good) | 
                3 - Some Effect (Poor) |
                4 - Strong Effect (Not Recommended)
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Material</th>
                        <th>Compatibility</th>
                    </tr>
                </thead>
                <tbody>
                    {result}
                </tbody>
            </Table>

        </Fragment>
    )
}