import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LeftNavBar } from '../components/leftNavBar';

export const CustomerService = () => {
    return (
        <Row>
            <LeftNavBar />
            <Col>
                <h2 style={{ fontWeight: 700, padding: 10 }}>
                    Customer Service
                </h2>
                <p>Pump Application Engineers’ vision is to ensure its clients receive a service second to none. In South East Queensland we will visit your site, where feasible, to ensure we recognise and understand the needs of your business and production methods. Furthermore, for any proposed changes you may consider in the manufacturing process, we will provide our expertise and advice to maintain the suitability and satisfactory operation of the pumping equipment.</p>
                <p>Do you know what pumps you have in service?</p>
                <p>Do you know the optimal operating conditions for long service life of your pumps?</p>
                <p>Do you know the manufacturer’s recommendations for use of your pumps?</p>
                <p>Pump Application Engineers will attend site and perform a pump services survey after which you will receive a written report on your selected pump equipment.  The report will provide you with a reference which will assist you to better manage your pumping equipment. To experience the benefits of this service we invite you to contact us.</p>
            </Col>
        </Row>
    )
}