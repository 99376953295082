export const chemicalList = [
    { label: 'Acetaldehyde', result: [-1, 1, 1, 1, 0, 2, 1, 1, 4, 0, 0, 3, 0, 4, 4, 1, 0, 1, 1, 4, 3, 2, 1, 4, 2, 2, 4, 2, 3, 1, 1, 1, 0] },
    { label: 'Acetamide', result: [-1, 0, 2, 1, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 1, 1, 0, 1, 1, 4, 1, 0, 1, 0] },
    { label: 'Acetate Sol.', result: [-1, 1, 2, 1, 2, 2, 0, 0, 1, 3, 2, 1, 0, 2, 4, 1, 0, 0, 1, 0, 2, 4, 0, 4, 4, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Acetic Acid, Glacial', result: [-1, 0, 2, 1, 1, 2, 1, 1, 3, 3, 4, 1, 0, 3, 2, 1, 3, 4, 4, 4, 2, 2, 1, 4, 4, 2, 3, 2, 3, 2, 1, 1, 0] },
    { label: 'Acetic Acid 20%', result: [-1, 0, 2, 1, 0, 0, 1, 1, 0, 3, 0, 0, 1, 2, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 3, 0, 3, 0, 0, 2, 0, 1, 0] },
    { label: 'Acetic Acid 80%', result: [-1, 0, 2, 1, 0, 0, 1, 1, 0, 3, 0, 0, 1, 4, 0, 1, 2, 0, 4, 0, 0, 2, 0, 1, 3, 0, 4, 0, 0, 2, 0, 1, 0] },
    { label: 'Acetic Acid', result: [-1, 0, 2, 1, 2, 2, 1, 1, 3, 3, 4, 3, 2, 1, 2, 1, 1, 4, 4, 3, 2, 1, 1, 3, 3, 0, 3, 2, 3, 1, 1, 1, 0] },
    { label: 'Acetic Anhydride', result: [-1, 2, 1, 1, 2, 2, 1, 1, 3, 4, 2, 4, 4, 4, 4, 1, 4, 4, 4, 4, 1, 1, 1, 4, 1, 3, 2, 2, 3, 1, 1, 1, 0] },
    { label: 'Acetone', result: [-1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 4, 4, 4, 1, 4, 2, 1, 4, 3, 2, 1, 4, 4, 2, 3, 1, 4, 2, 1, 1, 1] },
    { label: 'Acetyl Chloride', result: [-1, 0, 3, 1, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0,] },
    { label: 'Acetylene', result: [-1, 1, 1, 1, 1, 1, 2, 0, 2, 0, 1, 1, 0, 2, 0, 0, 0, 1, 1, 0, 0, 4, 1, 1, 1, 3, 2, 1, 3, 1, 1, 1, 0] },
    { label: 'Acrylonitrile', result: [-1, 1, 1, 3, 0, 2, 2, 2, 1, 0, 3, 0, 0, 0, 0, 0, 0, 2, 0, 4, 0, 2, 1, 3, 4, 0, 4, 4, 0, 1, 1, 1, 0] },
    { label: 'Amyl', result: [-1, 1, 1, 1, 0, 3, 1, 1, 1, 2, 3, 3, 1, 1, 2, 1, 3, 1, 1, 2, 2, 2, 1, 1, 1, 4, 1, 1, 3, 1, 1, 1, 0] },
    { label: 'Benzyl Alcohol', result: [-1, 0, 1, 1, 0, 2, 1, 1, 1, 3, 0, 0, 0, 4, 2, 0, 1, 1, 1, 4, 4, 1, 0, 1, 4, 0, 2, 2, 4, 1, 1, 1, 0] },
    { label: 'Butyl Alcohol', result: [-1, 1, 1, 1, 0, 2, 2, 1, 2, 3, 3, 3, 1, 1, 2, 1, 1, 1, 1, 0, 2, 2, 1, 1, 1, 4, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Diacetone Alcohol', result: [-1, 0, 1, 1, 0, 1, 1, 1, 1, 3, 0, 1, 0, 4, 0, 0, 1, 1, 1, 0, 0, 4, 0, 4, 4, 0, 4, 1, 4, 1, 1, 1, 0] },
    { label: 'Ethyl Alcohol', result: [-1, 0, 1, 1, 1, 2, 1, 1, 1, 3, 1, 1, 0, 1, 3, 0, 1, 2, 1, 2, 2, 1, 0, 1, 1, 2, 1, 2, 1, 1, 1, 1, 1] },
    { label: 'Hexyl Alcohol', result: [-1, 0, 1, 1, 0, 1, 1, 1, 1, 3, 0, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 4, 2, 1, 1, 1, 1, 1, 0] },
    { label: 'Isobutyl Alcohol', result: [-1, 0, 1, 1, 0, 2, 1, 1, 1, 3, 0, 1, 0, 0, 0, 0, 1, 1, 1, 2, 0, 1, 0, 1, 3, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Isopropyl Alcohol', result: [-1, 0, 1, 1, 0, 2, 1, 1, 1, 3, 3, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 3, 3, 2, 1, 1, 1, 1, 1, 0] },
    { label: 'Methyl Alcohol', result: [-1, 0, 1, 1, 1, 2, 1, 1, 1, 3, 1, 1, 0, 2, 0, 1, 1, 3, 1, 4, 2, 1, 0, 3, 2, 0, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Octyl Alcohol', result: [-1, 0, 1, 1, 0, 1, 1, 1, 1, 3, 0, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 2, 0, 2, 1, 3, 1, 1, 1, 0] },
    { label: 'Propyl Alcohol', result: [-1, 0, 1, 1, 0, 1, 1, 1, 1, 0, 0, 1, 2, 1, 0, 1, 1, 1, 1, 0, 0, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Aluminium Chloride 20%', result: [-1, 0, 4, 3, 4, 2, 1, 1, 4, 0, 4, 1, 0, 1, 2, 0, 1, 3, 1, 0, 2, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Aluminium Chloride', result: [-1, 3, 4, 3, 0, 4, 3, 1, 3, 0, 4, 2, 1, 1, 1, 1, 1, 0, 4, 0, 0, 1, 1, 1, 1, 3, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Aluminium Fluoride', result: [-1, 0, 4, 3, 4, 0, 4, 2, 0, 0, 0, 1, 1, 1, 0, 1, 1, 3, 4, 0, 2, 1, 0, 1, 1, 3, 1, 0, 3, 1, 1, 0, 0] },
    { label: 'Aluminium Hydroxide', result: [-1, 0, 1, 1, 1, 1, 0, 0, 1, 0, 4, 1, 0, 1, 0, 1, 1, 2, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 1, 1, 1] },
    { label: 'Alum Potassium Sulphate (ALUM),10%', result: [-1, 0, 1, 0, 0, 1, 0, 2, 0, 0, 4, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Alum Potassium Sulphate (ALUM) 100%', result: [-1, 0, 4, 1, 2, 2, 0, 2, 3, 0, 0, 1, 0, 1, 2, 1, 1, 3, 4, 0, 2, 1, 0, 1, 1, 0, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Aluminium Sulphate', result: [-1, 0, 3, 3, 1, 1, 1, 1, 3, 3, 4, 1, 1, 1, 2, 1, 1, 3, 1, 0, 2, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Amines', result: [-1, 1, 1, 1, 0, 1, 2, 1, 2, 0, 1, 2, 0, 3, 1, 1, 2, 4, 1, 0, 0, 0, 0, 4, 4, 3, 2, 2, 3, 1, 1, 1, 0] },
    { label: 'Ammonia 10%', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 4, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 4, 0, 1, 0, 0, 2, 0, 1, 0] },
    { label: 'Ammonia Anhydrous', result: [-1, 1, 2, 1, 1, 2, 2, 1, 4, 0, 4, 2, 4, 1, 2, 1, 1, 4, 1, 0, 2, 1, 2, 4, 2, 2, 1, 1, 4, 1, 3, 1, 0] },
    { label: 'Ammonia, Liquids', result: [-1, 0, 1, 1, 1, 4, 0, 2, 4, 0, 1, 1, 0, 1, 2, 1, 1, 4, 0, 0, 4, 1, 0, 4, 2, 2, 1, 1, 4, 1, 1, 1, 0] },
    { label: 'Ammonia, Nitrate', result: [-1, 0, 1, 1, 1, 3, 0, 0, 4, 0, 0, 1, 0, 2, 2, 0, 1, 3, 0, 0, 0, 1, 0, 0, 1, 0, 3, 0, 0, 1, 1, 1, 0] },
    { label: 'Ammonium Bifluoride', result: [-1, 0, 3, 1, 0, 4, 0, 2, 0, 0, 0, 0, 0, 1, 0, 0, 1, 4, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Ammonium Carbonate', result: [-1, 2, 1, 1, 1, 3, 1, 2, 2, 0, 3, 2, 0, 1, 2, 1, 1, 4, 1, 0, 0, 1, 0, 2, 4, 3, 1, 1, 0, 1, 1, 1, 0] },
    { label: 'Ammonium Casenite', result: [-1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 4, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0] },
    { label: 'Ammonium Chloride', result: [-1, 3, 1, 3, 1, 3, 4, 1, 4, 3, 4, 4, 1, 1, 2, 1, 1, 2, 1, 0, 2, 1, 1, 1, 1, 3, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Ammonium Hydroxide', result: [-1, 1, 1, 1, 1, 3, 1, 1, 4, 4, 1, 3, 0, 1, 2, 1, 1, 4, 1, 2, 2, 1, 1, 2, 2, 2, 1, 1, 3, 1, 1, 1, 0] },
    { label: 'Ammonium Nitrate', result: [-1, 1, 1, 1, 1, 2, 1, 1, 4, 4, 1, 4, 0, 1, 2, 1, 1, 3, 4, 0, 2, 1, 1, 4, 1, 3, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Ammonium Oxalate', result: [-1, 0, 1, 1, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 1, 1, 0, 0] },
    { label: 'Ammonium Persulphate', result: [-1, 0, 1, 1, 1, 3, 3, 1, 1, 0, 4, 1, 4, 1, 0, 1, 1, 4, 4, 0, 0, 1, 0, 3, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Ammonium Phosphate, Dibasic', result: [-1, 2, 1, 1, 1, 2, 1, 1, 3, 0, 0, 4, 0, 1, 0, 1, 1, 2, 1, 0, 2, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Ammonium Phosphate, Monobasic', result: [-1, 0, 1, 1, 1, 2, 1, 1, 4, 0, 0, 1, 0, 1, 1, 1, 1, 2, 1, 0, 2, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Ammonium Phosphate, Tribasic', result: [-1, 2, 1, 1, 1, 2, 1, 1, 3, 0, 3, 4, 0, 1, 0, 1, 1, 2, 1, 0, 2, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Ammonium Sulphate', result: [-1, 3, 4, 2, 1, 2, 1, 1, 2, 3, 3, 3, 1, 1, 4, 1, 1, 2, 4, 0, 2, 1, 1, 4, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Ammonium Thiosulphate', result: [-1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 4, 1, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Amyl Acetate', result: [-1, 2, 1, 1, 3, 2, 1, 1, 3, 0, 0, 3, 3, 4, 4, 1, 4, 1, 2, 0, 4, 4, 1, 4, 4, 4, 4, 1, 4, 1, 1, 1, 0] },
    { label: 'Amyl Alcohol', result: [-1, 0, 1, 1, 0, 2, 1, 1, 1, 0, 0, 1, 1, 1, 2, 1, 3, 1, 1, 0, 2, 1, 0, 2, 2, 4, 1, 1, 3, 1, 1, 1, 0] },
    { label: 'Amyl Chloride', result: [-1, 0, 3, 2, 0, 4, 0, 1, 1, 0, 0, 1, 1, 4, 3, 1, 4, 1, 3, 0, 4, 4, 0, 1, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Aniline', result: [-1, 2, 1, 1, 1, 3, 1, 2, 3, 0, 0, 3, 3, 4, 4, 1, 4, 4, 3, 4, 3, 2, 1, 3, 4, 3, 4, 2, 4, 1, 1, 1, 0] },
    { label: 'Anti-Freeze', result: [-1, 0, 1, 1, 0, 1, 0, 1, 2, 2, 2, 3, 0, 1, 2, 1, 1, 1, 1, 2, 2, 1, 1, 1, 1, 3, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Antimony Trichloride', result: [-1, 0, 4, 4, 0, 4, 3, 1, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 4, 0, 1, 0, 0, 1, 0, 0, 3, 0, 1, 1, 0, 1, 0] },
    { label: 'Aqua Regia (80% HCl, 20% HNO)', result: [-1, 0, 4, 4, 0, 4, 1, 4, 4, 0, 0, 0, 3, 4, 4, 1, 4, 4, 4, 0, 4, 3, 0, 3, 4, 3, 4, 4, 4, 4, 0, 4, 0] },
    { label: 'Arochlor 1248', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 1, 4, 0, 4, 2, 4, 1, 1, 0, 0] },
    { label: 'Aromatic Hydrocarbons', result: [-1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 4, 1, 0, 0, 3, 0, 0, 1, 4, 0, 4, 4, 4, 1, 1, 0, 0] },
    { label: 'Arsenic Acid', result: [-1, 2, 1, 1, 0, 4, 0, 0, 4, 2, 4, 4, 1, 1, 2, 1, 1, 4, 1, 0, 2, 1, 0, 1, 1, 0, 1, 0, 3, 1, 1, 1, 0] },
    { label: 'Asphalt', result: [-1, 0, 2, 1, 0, 3, 0, 0, 1, 0, 3, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 1, 1, 1, 2, 3, 2, 4, 4, 1, 0, 1, 1] },
    { label: 'Barium Carbonate', result: [-1, 2, 1, 1, 1, 2, 1, 1, 2, 0, 2, 2, 0, 1, 1, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 0, 1, 0, 1, 1, 1, 1, 1] },
    { label: 'Barium Chloride', result: [-1, 3, 4, 1, 1, 4, 1, 1, 2, 0, 0, 3, 1, 1, 2, 1, 1, 1, 2, 0, 2, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Barium Cyanide', result: [-1, 0, 0, 1, 0, 0, 0, 0, 3, 0, 0, 1, 0, 0, 0, 0, 0, 2, 0, 0, 2, 0, 0, 1, 3, 0, 1, 1, 0, 1, 1, 0, 0] },
    { label: 'Barium Hydroxide', result: [-1, 2, 3, 1, 1, 4, 2, 2, 2, 0, 3, 3, 1, 1, 0, 1, 1, 4, 1, 0, 2, 1, 1, 1, 1, 3, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Barium Nitrate', result: [-1, 0, 1, 1, 0, 0, 1, 0, 4, 0, 1, 1, 0, 2, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1, 1, 0, 2, 1, 1, 0] },
    { label: 'Barium Sulphate', result: [-1, 2, 1, 1, 1, 4, 1, 1, 3, 0, 3, 3, 1, 1, 0, 1, 1, 1, 1, 0, 2, 1, 1, 1, 1, 4, 1, 1, 0, 2, 1, 2, 0] },
    { label: 'Barium Sulphide', result: [-1, 2, 1, 1, 0, 4, 2, 0, 3, 0, 3, 3, 0, 1, 1, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 3, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Beer', result: [-1, 1, 1, 1, 0, 1, 1, 1, 1, 2, 4, 4, 1, 1, 0, 1, 1, 2, 4, 2, 2, 4, 0, 1, 4, 3, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Beet Sugar Liquids', result: [-1, 1, 1, 1, 0, 1, 0, 0, 1, 2, 1, 0, 0, 1, 0, 1, 1, 2, 1, 2, 0, 1, 0, 1, 1, 0, 2, 1, 1, 1, 1, 1, 0] },
    { label: 'Benzaldehyde', result: [-1, 1, 1, 1, 0, 2, 1, 1, 1, 0, 2, 1, 3, 4, 4, 1, 4, 1, 3, 4, 4, 4, 1, 4, 4, 2, 4, 1, 4, 1, 1, 1, 0] },
    { label: 'Benzene', result: [-1, 2, 1, 1, 1, 2, 1, 2, 2, 1, 2, 3, 2, 4, 3, 1, 4, 1, 1, 4, 4, 4, 1, 1, 4, 0, 4, 4, 4, 1, 1, 1, 1] },
    { label: 'Benzoic Acid', result: [-1, 2, 1, 1, 1, 2, 1, 1, 2, 0, 4, 0, 1, 1, 2, 1, 1, 2, 4, 0, 2, 4, 0, 1, 4, 0, 4, 4, 4, 1, 1, 2, 0] },
    { label: 'Benzol', result: [-1, 0, 1, 1, 0, 2, 1, 1, 2, 1, 0, 0, 0, 4, 0, 1, 4, 1, 1, 0, 0, 1, 0, 4, 4, 0, 4, 0, 0, 1, 1, 1, 1] },
    { label: 'Borax (Sodium Borate)', result: [-1, 0, 1, 1, 1, 3, 2, 1, 1, 2, 1, 3, 1, 1, 1, 1, 1, 1, 1, 0, 2, 1, 1, 1, 2, 3, 1, 1, 3, 1, 1, 1, 1] },
    { label: 'Boric Acid', result: [-1, 2, 1, 1, 1, 2, 1, 1, 2, 3, 4, 0, 1, 1, 2, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Brewery Slop', result: [-1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Bromine (Wet)', result: [-1, 4, 4, 4, 4, 4, 1, 1, 3, 0, 4, 4, 1, 2, 2, 1, 4, 4, 4, 4, 4, 4, 4, 1, 4, 4, 4, 4, 4, 3, 4, 1, 4] },
    { label: 'Butadene', result: [-1, 1, 1, 1, 0, 1, 0, 0, 3, 1, 3, 3, 1, 1, 0, 1, 0, 1, 1, 0, 0, 0, 2, 1, 1, 0, 2, 1, 0, 1, 1, 1, 0] },
    { label: 'Butane', result: [-1, 1, 1, 1, 0, 1, 0, 0, 1, 1, 3, 3, 1, 1, 3, 1, 4, 1, 1, 2, 3, 4, 1, 1, 1, 4, 2, 4, 4, 1, 1, 1, 0] },
    { label: 'Butanol', result: [-1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Butter', result: [-1, 0, 2, 1, 0, 1, 0, 0, 4, 0, 4, 0, 0, 0, 2, 0, 2, 1, 0, 2, 0, 0, 0, 1, 1, 0, 2, 1, 4, 1, 1, 1, 0] },
    { label: 'Buttermilk', result: [-1, 1, 1, 1, 1, 1, 0, 0, 4, 0, 4, 0, 0, 0, 2, 1, 1, 1, 1, 2, 0, 0, 0, 1, 1, 0, 1, 0, 4, 1, 1, 1, 0] },
    { label: 'Butylene', result: [-1, 1, 2, 1, 0, 1, 0, 0, 1, 1, 1, 1, 0, 2, 0, 1, 0, 1, 0, 0, 0, 0, 1, 1, 2, 0, 0, 4, 4, 1, 1, 1, 0] },
    { label: 'Butyl Acetate', result: [-1, 0, 0, 3, 0, 1, 0, 1, 1, 0, 0, 1, 3, 4, 4, 1, 4, 1, 0, 0, 3, 4, 1, 4, 2, 4, 4, 2, 4, 1, 1, 1, 0] },
    { label: 'Butyric Acid', result: [-1, 2, 2, 1, 1, 2, 1, 1, 3, 0, 4, 0, 1, 2, 0, 1, 1, 3, 4, 4, 0, 1, 0, 4, 4, 0, 4, 2, 0, 1, 1, 4, 0] },
    { label: 'Calcium Bisulphate', result: [-1, 3, 4, 1, 0, 4, 0, 0, 4, 4, 4, 0, 0, 1, 1, 1, 0, 0, 1, 0, 0, 0, 0, 1, 1, 3, 3, 0, 1, 1, 0, 0, 0] },
    { label: 'Calcium Bisulphide', result: [-1, 0, 0, 2, 0, 3, 1, 1, 3, 0, 0, 0, 0, 1, 0, 1, 1, 4, 1, 0, 2, 1, 0, 1, 1, 0, 1, 4, 0, 1, 1, 1, 0] },
    { label: 'Calcium Bisufite', result: [-1, 0, 2, 1, 0, 3, 1, 1, 3, 0, 0, 0, 1, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0] },
    { label: 'Calcium Carbonate', result: [-1, 2, 1, 1, 1, 3, 1, 1, 3, 0, 4, 0, 0, 1, 1, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 0, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Calcium Chlorate', result: [-1, 0, 2, 1, 0, 0, 0, 2, 3, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 0] },
    { label: 'Calcium Chloride', result: [-1, 3, 1, 4, 3, 3, 1, 1, 2, 0, 3, 0, 1, 1, 1, 1, 1, 4, 1, 2, 2, 1, 1, 1, 1, 2, 4, 1, 1, 1, 1, 1, 2] },
    { label: 'Calcium Hydroxide', result: [-1, 2, 1, 1, 0, 3, 1, 1, 2, 0, 0, 0, 0, 1, 1, 1, 1, 2, 1, 0, 2, 1, 0, 1, 1, 3, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Calcium Hypochlorite', result: [-1, 4, 4, 3, 3, 3, 1, 2, 4, 0, 4, 0, 1, 4, 0, 1, 1, 4, 4, 0, 2, 1, 0, 1, 2, 3, 4, 1, 3, 1, 1, 1, 0] },
    { label: 'Calcium Sulphate', result: [-1, 2, 1, 1, 1, 2, 1, 2, 2, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 3, 2, 1, 1, 1, 1, 0, 4, 0, 3, 1, 1, 1, 0] },
    { label: 'Calgon', result: [-1, 0, 1, 1, 0, 0, 0, 0, 3, 0, 4, 0, 0, 0, 0, 0, 1, 2, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Cane Juice', result: [-1, 0, 1, 1, 0, 2, 0, 0, 2, 3, 1, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 4, 0, 0, 1, 0, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Carbolic Acid (See Phenol)', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
    { label: 'Carbon Bisulphide', result: [-1, 2, 1, 1, 1, 1, 0, 0, 3, 0, 2, 0, 0, 4, 4, 0, 0, 1, 1, 0, 0, 4, 0, 1, 4, 0, 4, 4, 4, 1, 1, 1, 1] },
    { label: 'Carbon Dioxide (Wet)', result: [-1, 0, 1, 1, 0, 3, 0, 1, 3, 3, 3, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0] },
    { label: 'Carbon Disulfide', result: [-1, 0, 2, 1, 0, 3, 0, 0, 3, 3, 2, 3, 0, 4, 3, 1, 4, 1, 1, 0, 4, 4, 1, 1, 4, 0, 4, 4, 4, 1, 1, 2, 0] },
    { label: 'Carbon Monoxide', result: [-1, 0, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 2, 1, 1, 0, 2, 1, 0, 1, 1, 2, 2, 1, 3, 1, 1, 1, 0] },
    { label: 'Carbon Tetrachloride', result: [-1, 2, 2, 2, 1, 3, 1, 1, 3, 1, 3, 4, 1, 3, 3, 1, 4, 1, 1, 4, 4, 4, 3, 1, 3, 3, 4, 0, 4, 3, 1, 1, 1] },
    { label: 'Carbonated Water', result: [-1, 2, 1, 1, 1, 1, 0, 0, 2, 0, 4, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 0] },
    { label: 'Carbonic Acid', result: [-1, 2, 1, 2, 1, 1, 0, 1, 2, 0, 4, 0, 1, 1, 0, 1, 1, 1, 1, 0, 2, 1, 0, 1, 2, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Catsup', result: [-1, 0, 1, 1, 1, 4, 0, 0, 3, 0, 4, 0, 0, 1, 0, 0, 1, 2, 1, 2, 0, 1, 0, 1, 1, 0, 3, 0, 0, 1, 1, 1, 0] },
    { label: 'Chloracetic Acid', result: [-1, 4, 4, 4, 4, 3, 1, 1, 4, 0, 4, 0, 4, 1, 4, 1, 0, 4, 4, 0, 4, 4, 0, 4, 4, 0, 4, 2, 4, 2, 1, 1, 0] },
    { label: 'Chloric Acid', result: [-1, 0, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 4, 0, 0, 4, 0, 0, 0,] },
    { label: 'Chlorinated Glue', result: [-1, 0, 1, 1, 0, 4, 0, 0, 3, 0, 4, 0, 0, 0, 0, 0, 3, 0, 3, 4, 0, 0, 0, 1, 3, 0, 4, 2, 4, 1, 0, 1, 0] },
    { label: 'Chlorine, Anhydrous Liquid', result: [-1, 0, 4, 4, 4, 4, 4, 1, 4, 0, 3, 0, 0, 4, 2, 1, 1, 4, 4, 0, 4, 4, 3, 1, 4, 0, 4, 2, 4, 2, 1, 4, 0,] },
    { label: 'Chlorine (Dry)', result: [-1, 2, 1, 1, 0, 4, 4, 1, 1, 2, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 3, 4, 0, 0, 4, 0, 4, 4, 1, 1, 0] },
    { label: 'Chlorine Water', result: [-1, 4, 0, 4, 0, 4, 1, 2, 4, 4, 4, 0, 1, 1, 0, 1, 3, 0, 4, 0, 0, 4, 3, 1, 4, 3, 4, 0, 0, 0, 3, 1, 0] },
    { label: 'Chlorobenzene (Mono)', result: [-1, 1, 1, 1, 0, 2, 0, 1, 2, 0, 2, 3, 1, 4, 4, 1, 4, 1, 1, 4, 4, 4, 1, 1, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Chloroform', result: [-1, 1, 1, 1, 1, 4, 1, 1, 2, 0, 4, 3, 3, 4, 3, 1, 4, 1, 3, 4, 4, 4, 3, 1, 4, 4, 4, 4, 4, 1, 1, 1, 1] },
    { label: 'Chlorosulfonic Acid', result: [-1, 4, 4, 0, 4, 4, 1, 2, 4, 0, 0, 4, 4, 3, 3, 1, 4, 4, 4, 0, 4, 4, 4, 4, 4, 4, 4, 4, 4, 3, 0, 3, 0,] },
    { label: 'Chlorox (Bleach)', result: [-1, 0, 1, 1, 0, 3, 0, 1, 1, 0, 4, 3, 0, 1, 2, 1, 1, 4, 4, 2, 0, 4, 3, 1, 3, 0, 2, 2, 4, 1, 1, 1, 0] },
    { label: 'Chocolate Syrup', result: [-1, 0, 1, 1, 0, 1, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 4, 1, 0, 1, 0] },
    { label: 'Chromic Acid 5%', result: [-1, 0, 1, 1, 2, 3, 1, 1, 4, 4, 4, 0, 0, 1, 2, 0, 3, 4, 4, 2, 2, 1, 1, 1, 4, 3, 4, 1, 2, 2, 4, 3, 0,] },
    { label: 'Chromic Acid 10%', result: [-1, 0, 2, 0, 0, 0, 1, 1, 0, 4, 0, 0, 1, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 4, 0, 4, 0, 0, 3, 0, 1, 0] },
    { label: 'Chromic Acid 30%', result: [-1, 0, 2, 0, 0, 0, 1, 1, 0, 4, 0, 0, 2, 1, 0, 1, 4, 0, 4, 0, 0, 1, 0, 1, 4, 0, 4, 0, 0, 4, 0, 1, 0] },
    { label: 'Chromic Acid 50%', result: [-1, 3, 2, 2, 0, 3, 1, 1, 4, 4, 4, 0, 3, 2, 2, 1, 4, 4, 4, 3, 3, 2, 2, 1, 4, 0, 4, 1, 4, 3, 4, 1, 0] },
    { label: 'Cider', result: [-1, 0, 1, 1, 1, 2, 0, 0, 1, 0, 4, 0, 0, 1, 0, 0, 1, 2, 0, 0, 2, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Citric Acid', result: [-1, 0, 1, 1, 1, 3, 1, 1, 4, 3, 4, 0, 1, 1, 0, 1, 1, 2, 3, 3, 2, 2, 0, 1, 4, 3, 1, 1, 1, 1, 1, 1, 2] },
    { label: 'Citric Oils', result: [-1, 0, 1, 1, 0, 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 0, 1, 0, 1, 1, 3, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Coffee', result: [-1, 1, 1, 1, 1, 1, 0, 0, 2, 0, 3, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Copper Chloride', result: [-1, 3, 4, 4, 2, 4, 1, 1, 4, 0, 4, 0, 1, 1, 2, 1, 1, 2, 4, 0, 2, 1, 1, 1, 1, 0, 1, 1, 1, 1, 0, 1, 0] },
    { label: 'Copper Cyanide', result: [-1, 0, 1, 1, 1, 4, 1, 1, 3, 0, 4, 0, 1, 1, 0, 1, 1, 2, 1, 0, 2, 1, 1, 2, 2, 0, 1, 1, 1, 3, 1, 1, 0] },
    { label: 'Copper Floborate', result: [-1, 0, 4, 4, 0, 4, 0, 2, 4, 0, 4, 0, 0, 1, 0, 1, 0, 2, 0, 0, 1, 0, 0, 1, 2, 0, 1, 0, 1, 1, 1, 0, 0] },
    { label: 'Copper Nitrate', result: [-1, 2, 1, 1, 2, 4, 1, 1, 4, 0, 0, 0, 1, 1, 0, 1, 1, 2, 4, 0, 2, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Copper Sulphate 5% solution', result: [-1, 0, 1, 1, 1, 4, 1, 1, 4, 4, 4, 0, 0, 1, 0, 1, 1, 2, 4, 0, 2, 1, 1, 1, 1, 3, 1, 0, 3, 1, 1, 1, 0] },
    { label: 'Copper Sulphate', result: [-1, 2, 2, 0, 0, 0, 1, 1, 3, 4, 0, 0, 1, 1, 0, 1, 1, 0, 3, 0, 0, 1, 0, 2, 2, 0, 1, 1, 0, 1, 0, 1, 0] },
    { label: 'Cream', result: [-1, 0, 1, 1, 0, 1, 0, 0, 3, 0, 4, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 3, 0, 0, 1, 1, 1, 0] },
    { label: 'Cresols', result: [-1, 0, 1, 1, 0, 2, 0, 0, 4, 3, 0, 0, 0, 4, 4, 0, 0, 4, 0, 4, 4, 3, 1, 4, 4, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Cresylic Acid', result: [-1, 2, 1, 1, 0, 3, 1, 2, 3, 0, 0, 0, 2, 2, 4, 1, 0, 4, 4, 0, 3, 0, 0, 1, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Cyclohexane', result: [-1, 0, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0, 4, 0, 4, 1, 0, 0, 0, 4, 1, 1, 1, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Cyanic Acid', result: [-1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 3, 0, 4, 0, 0, 1, 0, 0, 0,] },
    { label: 'Detergents', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 2, 1, 2, 2, 1, 1, 1, 1, 0, 2, 1, 3, 1, 1, 1, 0] },
    { label: 'Dichlorethane', result: [-1, 0, 1, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 4, 4, 1, 0, 0, 1, 0, 4, 0, 0, 2, 0, 0, 4, 0, 4, 1, 0, 0, 0,] },
    { label: 'Diesel Fuel', result: [-1, 1, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 0, 0, 0, 4, 1, 0, 0, 0, 4, 1, 1, 1, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Diethylamine', result: [-1, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 4, 0, 1, 2, 4, 0, 0, 0, 3, 0, 4, 2, 0, 2, 2, 3, 1, 1, 1, 0] },
    { label: 'Diethylene Glycol', result: [-1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 2, 0, 0, 1, 1, 3, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Diphenyl Oxide', result: [-1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Dyes', result: [-1, 0, 1, 1, 0, 2, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 3, 0, 0, 1, 0, 0, 0,] },
    { label: 'Esom Salts (Magnesium Sulphate)', result: [-1, 2, 1, 1, 1, 1, 1, 2, 2, 0, 0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 3, 1, 1, 1, 0] },
    { label: 'Ethane', result: [-1, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 4, 1, 0, 0, 0, 0, 0, 1, 1, 0, 2, 4, 4, 1, 1, 1, 0] },
    { label: 'Ethanolamine', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 1, 4, 2, 3, 2, 0, 3, 1, 1, 1, 0] },
    { label: 'Ether', result: [-1, 1, 1, 1, 1, 1, 0, 2, 2, 1, 0, 2, 0, 4, 3, 0, 4, 1, 3, 0, 0, 0, 1, 3, 4, 0, 4, 3, 4, 1, 1, 1, 1] },
    { label: 'Ethyl Acetate', result: [-1, 0, 1, 1, 0, 2, 0, 2, 2, 0, 0, 3, 4, 4, 4, 1, 4, 1, 1, 4, 3, 3, 1, 4, 4, 3, 4, 2, 4, 1, 1, 1, 0] },
    { label: 'Ethyl Chloride', result: [-1, 0, 1, 1, 1, 2, 1, 2, 2, 0, 3, 4, 1, 4, 4, 1, 4, 1, 1, 0, 4, 4, 1, 1, 4, 4, 3, 1, 1, 1, 1, 1, 0] },
    { label: 'Ethyl Sulphate', result: [-1, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Ethylene Chloride', result: [-1, 0, 1, 1, 0, 3, 2, 2, 1, 0, 3, 3, 0, 4, 0, 1, 4, 1, 0, 4, 0, 4, 1, 1, 4, 4, 4, 3, 4, 1, 1, 1, 0] },
    { label: 'Ethylene Dichloride', result: [-1, 0, 1, 1, 0, 4, 1, 2, 3, 0, 0, 3, 0, 4, 4, 1, 4, 1, 1, 0, 4, 1, 1, 1, 4, 4, 4, 3, 4, 1, 3, 1, 0] },
    { label: 'Ethylene Glycol', result: [-1, 0, 1, 1, 0, 1, 0, 1, 2, 2, 2, 3, 1, 1, 2, 1, 1, 1, 1, 2, 2, 1, 1, 1, 1, 3, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Ethylene Oxide', result: [-1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 0, 4, 0, 1, 1, 1, 1, 0, 0, 0, 0, 4, 4, 4, 4, 3, 4, 1, 1, 1, 0] },
    { label: 'Fatty Acids', result: [-1, 0, 1, 1, 0, 2, 1, 1, 3, 0, 4, 0, 1, 1, 2, 1, 2, 1, 1, 0, 2, 1, 0, 1, 3, 3, 2, 3, 3, 1, 1, 1, 0] },
    { label: 'Ferric Chloride', result: [-1, 0, 4, 4, 4, 4, 1, 2, 4, 4, 4, 0, 1, 1, 2, 1, 1, 2, 4, 0, 2, 1, 1, 1, 4, 3, 2, 1, 1, 1, 1, 1, 0] },
    { label: 'Ferric Nitrate', result: [-1, 0, 1, 1, 1, 4, 1, 1, 4, 0, 0, 0, 1, 1, 0, 1, 1, 2, 4, 0, 2, 1, 1, 1, 1, 4, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Ferric Sulphate', result: [-1, 0, 1, 3, 1, 4, 1, 1, 4, 4, 4, 0, 1, 1, 2, 1, 1, 2, 1, 3, 0, 1, 1, 1, 2, 3, 1, 0, 1, 1, 3, 1, 0] },
    { label: 'Ferrous Chloride', result: [-1, 0, 4, 4, 0, 4, 1, 2, 3, 0, 4, 0, 1, 1, 2, 1, 1, 2, 4, 0, 2, 1, 1, 1, 2, 3, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Ferrous Sulphate', result: [-1, 2, 1, 3, 0, 4, 1, 2, 3, 0, 4, 4, 1, 1, 2, 1, 1, 2, 4, 0, 2, 1, 1, 1, 2, 0, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Fluboric Acid', result: [-1, 0, 4, 2, 0, 0, 4, 1, 0, 0, 4, 0, 1, 1, 2, 1, 2, 2, 3, 0, 2, 1, 0, 1, 2, 0, 1, 0, 0, 1, 1, 4, 0,] },
    { label: 'Fluorine', result: [-1, 4, 4, 4, 0, 4, 4, 1, 4, 0, 4, 4, 0, 3, 0, 3, 0, 0, 4, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 0, 0,] },
    { label: 'Fluosilicic Acid', result: [-1, 0, 0, 2, 0, 4, 4, 2, 0, 0, 4, 0, 1, 1, 2, 1, 1, 2, 4, 0, 2, 1, 0, 2, 1, 0, 1, 0, 0, 3, 1, 4, 0,] },
    { label: 'Formaldehyde 40%', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 2, 2, 0, 1, 1, 0, 4, 0, 0, 1, 1, 4, 2, 2, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Formaldehyde', result: [-1, 1, 1, 1, 0, 1, 1, 2, 1, 2, 4, 1, 0, 1, 2, 1, 4, 1, 1, 0, 2, 1, 1, 4, 3, 2, 4, 2, 3, 1, 1, 1, 0] },
    { label: 'Formic Acid', result: [-1, 3, 1, 2, 2, 4, 3, 1, 3, 3, 4, 4, 1, 4, 2, 1, 1, 4, 4, 0, 2, 1, 1, 2, 4, 3, 4, 1, 3, 2, 1, 1, 2] },
    { label: 'Freon 11', result: [-1, 0, 0, 1, 0, 4, 0, 0, 0, 0, 3, 0, 0, 0, 0, 1, 0, 1, 4, 0, 0, 4, 0, 3, 3, 0, 4, 0, 0, 0, 0, 0, 0,] },
    { label: 'Freon 12 (wet)', result: [-1, 0, 0, 1, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 4, 0, 0, 2, 0, 1, 1, 0, 2, 0, 0, 0, 0, 0, 0,] },
    { label: 'Freon 22', result: [-1, 0, 0, 1, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 2, 0, 0, 4, 0, 4, 4, 0, 1, 0, 0, 0, 0, 0, 0,] },
    { label: 'Freon 113', result: [-1, 0, 0, 1, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 4, 0, 3, 1, 0, 1, 0, 0, 0, 0, 0, 0,] },
    { label: 'Fruit Juice', result: [-1, 1, 1, 1, 1, 2, 0, 0, 2, 0, 4, 4, 0, 1, 0, 4, 1, 2, 1, 0, 2, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 1] },
    { label: 'Fuel Oils', result: [-1, 1, 1, 1, 0, 1, 1, 1, 2, 0, 3, 2, 1, 1, 0, 1, 1, 1, 1, 0, 4, 2, 1, 1, 1, 3, 2, 4, 4, 1, 1, 1, 0] },
    { label: 'Furan Resin', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 1, 1, 4, 0, 4, 0, 4, 1, 0, 1, 0] },
    { label: 'Furfural', result: [-1, 1, 1, 1, 0, 1, 0, 2, 1, 0, 0, 1, 4, 4, 0, 1, 4, 2, 1, 4, 4, 4, 1, 4, 4, 4, 4, 2, 4, 1, 1, 1, 0] },
    { label: 'Gallic Acid', result: [-1, 2, 1, 1, 0, 1, 0, 1, 1, 0, 4, 4, 0, 1, 1, 1, 0, 0, 1, 0, 0, 0, 0, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Gasoline', result: [-1, 1, 1, 1, 1, 1, 4, 1, 1, 0, 1, 1, 1, 3, 0, 1, 4, 1, 1, 4, 4, 3, 1, 1, 1, 4, 4, 3, 4, 1, 1, 1, 1] },
    { label: 'Gelatine', result: [-1, 1, 1, 1, 1, 1, 0, 1, 1, 3, 4, 4, 0, 1, 0, 1, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Glucose', result: [-1, 1, 0, 1, 0, 1, 0, 0, 1, 1, 2, 2, 0, 1, 2, 1, 2, 1, 1, 2, 2, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Glue P.V.A.', result: [-1, 2, 2, 1, 0, 2, 1, 0, 1, 0, 0, 1, 0, 1, 2, 1, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Glycerine', result: [-1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 1, 1, 2, 1, 1, 1, 1, 3, 0, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Cycolic Acid', result: [-1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 1, 3, 0, 0, 2, 1, 1, 1, 1, 0, 1, 0, 0, 1, 1, 0, 0] },
    { label: 'Gold Monocyanide', result: [-1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 4, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Grape Juice', result: [-1, 0, 1, 1, 0, 2, 0, 0, 2, 0, 4, 0, 0, 1, 0, 0, 1, 2, 0, 2, 2, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Grease', result: [-1, 1, 1, 1, 0, 1, 0, 0, 2, 0, 1, 1, 0, 0, 0, 1, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Heptane', result: [-1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 2, 1, 1, 0, 1, 4, 1, 1, 3, 4, 4, 1, 1, 1, 0, 2, 4, 0, 1, 1, 1, 0] },
    { label: 'Hexane', result: [-1, 1, 1, 1, 0, 1, 0, 1, 2, 0, 0, 2, 1, 3, 0, 1, 4, 1, 1, 4, 0, 3, 1, 1, 1, 2, 2, 4, 4, 1, 1, 1, 0] },
    { label: 'Honey', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 1, 2, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 0] },
    { label: 'Hydraulic Oils (Petroleum)', result: [-1, 1, 1, 1, 0, 1, 0, 0, 2, 0, 1, 1, 0, 0, 0, 1, 0, 1, 1, 0, 0, 4, 0, 1, 1, 0, 2, 4, 4, 1, 1, 1, 0] },
    { label: 'Hydraulic Oils (Synthetic)', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 4, 0, 1, 3, 4, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Hydrazine', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 1, 2, 4, 2, 1, 3, 1, 1, 0, 0] },
    { label: 'Hydrobromic Acid 20%', result: [-1, 0, 0, 4, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 4, 0, 3, 0, 0, 2, 0, 2, 0] },
    { label: 'Hydrobromic Acid', result: [-1, 4, 4, 4, 4, 4, 1, 1, 4, 0, 4, 4, 1, 1, 2, 1, 3, 4, 4, 0, 2, 2, 0, 1, 4, 4, 4, 1, 1, 1, 1, 1, 0] },
    { label: 'Hydrochloric/Muriatic Acid (Dry Gas)', result: [-1, 4, 3, 1, 0, 4, 0, 1, 0, 0, 0, 4, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 0] },
    { label: 'Hydrochloric/ Muriatic Acid (20%)', result: [-1, 0, 4, 4, 4, 4, 3, 2, 4, 0, 4, 0, 1, 1, 2, 1, 1, 4, 4, 2, 1, 1, 4, 1, 3, 0, 3, 1, 3, 1, 1, 1, 4] },
    { label: 'Hydrochloric Acid/Muriatic (37%)', result: [-1, 0, 4, 4, 4, 4, 3, 2, 4, 0, 4, 0, 1, 1, 2, 1, 1, 4, 4, 3, 1, 1, 4, 1, 3, 3, 3, 3, 4, 1, 1, 3, 4] },
    { label: 'Hydrochloric Acid/Muriatic 100%', result: [-1, 0, 4, 4, 0, 4, 4, 3, 4, 0, 4, 0, 0, 1, 1, 1, 0, 0, 4, 0, 1, 0, 0, 3, 4, 0, 3, 0, 1, 1, 1, 3, 0,] },
    { label: 'Hydrocyanic Acid', result: [-1, 1, 1, 1, 3, 1, 1, 1, 4, 4, 0, 3, 0, 1, 2, 1, 1, 2, 1, 0, 2, 1, 0, 1, 3, 0, 2, 0, 1, 1, 1, 1, 0] },
    { label: 'Hydrocyanic Acid (Gas 10%)', result: [-1, 0, 4, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 1, 3, 1, 0, 0, 0,] },
    { label: 'Hydrofluoric Acid (20%)', result: [-1, 0, 4, 4, 4, 4, 4, 2, 4, 0, 4, 0, 0, 4, 2, 1, 1, 4, 4, 0, 3, 1, 3, 1, 4, 0, 3, 1, 3, 2, 2, 3, 4] },
    { label: 'Hydrofluoric Acid (75%)', result: [-1, 0, 3, 4, 0, 4, 4, 3, 4, 0, 4, 0, 1, 3, 2, 1, 4, 4, 4, 0, 3, 2, 3, 1, 4, 4, 4, 3, 3, 3, 4, 4, 4] },
    { label: 'Hydrofluoric Acid 100%', result: [-1, 4, 4, 4, 0, 4, 4, 2, 4, 0, 4, 4, 0, 3, 4, 1, 0, 0, 0, 0, 4, 0, 3, 0, 4, 0, 4, 0, 4, 1, 4, 4, 0,] },
    { label: 'Hydrofluosilicic Acid (20%)', result: [-1, 0, 4, 4, 0, 4, 4, 2, 1, 0, 4, 0, 0, 4, 0, 1, 2, 4, 4, 0, 0, 1, 0, 1, 2, 0, 2, 1, 1, 3, 1, 4, 0,] },
    { label: 'Hydrofluosilicic Acid', result: [-1, 0, 4, 4, 0, 3, 0, 3, 4, 0, 0, 0, 0, 0, 3, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 1, 0, 0, 0, 1, 0, 0] },
    { label: 'Hydrogen Gas', result: [-1, 1, 1, 1, 0, 1, 0, 0, 1, 0, 2, 2, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0,] },
    { label: 'Hydrogen Peroxide 10%', result: [-1, 0, 3, 3, 0, 1, 3, 1, 4, 4, 4, 0, 0, 1, 1, 1, 0, 0, 4, 0, 1, 0, 2, 0, 1, 0, 4, 0, 3, 4, 1, 1, 0] },
    { label: 'Hydrogen Peroxide 30%', result: [-1, 0, 0, 2, 0, 0, 2, 1, 0, 4, 0, 0, 0, 1, 0, 1, 0, 0, 4, 0, 0, 1, 3, 1, 4, 0, 3, 0, 0, 2, 0, 0, 0,] },
    { label: 'Hydrogen Peroxide', result: [-1, 0, 1, 2, 1, 1, 2, 1, 4, 4, 4, 4, 3, 1, 3, 1, 2, 4, 4, 0, 2, 1, 3, 1, 4, 3, 4, 3, 3, 1, 0, 1, 1] },
    { label: 'Hydrogen Sulphide, Aqueous Solution', result: [-1, 0, 4, 1, 3, 3, 1, 1, 4, 3, 4, 0, 1, 1, 2, 1, 1, 4, 4, 0, 2, 1, 1, 4, 3, 0, 2, 1, 4, 1, 1, 1, 1] },
    { label: 'Hydrogen Sulphide (Dry)', result: [-1, 1, 3, 1, 0, 4, 0, 1, 4, 3, 2, 2, 0, 1, 0, 1, 0, 0, 4, 0, 0, 0, 1, 4, 0, 0, 0, 0, 1, 1, 0, 1, 0] },
    { label: 'Hydroxyacetic Acid (70%)', result: [-1, 0, 0, 0, 0, 4, 2, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 4, 0, 0, 0, 0, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 0] },
    { label: 'Ink', result: [-1, 1, 1, 1, 0, 3, 0, 0, 3, 0, 4, 4, 0, 0, 0, 0, 2, 1, 1, 0, 2, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 1] },
    { label: 'Iodine', result: [-1, 0, 4, 4, 4, 4, 1, 2, 4, 0, 4, 0, 0, 4, 2, 1, 1, 3, 4, 4, 4, 4, 0, 1, 2, 0, 4, 2, 4, 1, 4, 1, 0] },
    { label: 'Iodine (In Alcohol)', result: [-1, 0, 0, 2, 0, 0, 4, 1, 0, 0, 0, 0, 0, 4, 0, 1, 3, 0, 4, 0, 0, 2, 0, 1, 4, 0, 4, 0, 0, 0, 0, 1, 0] },
    { label: 'Iodoform', result: [-1, 2, 3, 1, 0, 1, 0, 0, 3, 0, 3, 2, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Isotane', result: [-1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 1, 0, 0, 0, 4, 0, 1, 1, 0, 0, 0, 4, 1, 0, 1, 0] },
    { label: 'Isopropyl Acetate', result: [-1, 0, 0, 2, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 4, 4, 0, 4, 2, 4, 1, 1, 1, 0] },
    { label: 'Isopropyl Ether', result: [-1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 4, 1, 0, 0, 0, 4, 0, 4, 2, 0, 4, 4, 4, 0, 1, 1, 0] },
    { label: 'Jet Fuel (JP3,JP4,JP5)', result: [-1, 1, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1, 0, 1, 4, 1, 1, 0, 0, 4, 1, 1, 1, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Kerosene', result: [-1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 4, 1, 4, 1, 1, 2, 4, 4, 1, 1, 1, 4, 4, 1, 4, 1, 1, 1, 1] },
    { label: 'Ketones', result: [-1, 1, 1, 1, 0, 2, 1, 1, 1, 0, 1, 1, 4, 4, 4, 1, 4, 2, 1, 0, 4, 4, 1, 4, 4, 0, 4, 4, 3, 3, 3, 1, 0] },
    { label: 'Lacquers', result: [-1, 1, 1, 1, 0, 1, 0, 0, 1, 3, 3, 3, 0, 0, 4, 0, 3, 1, 1, 0, 0, 1, 0, 4, 4, 0, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Lacquer Thinners', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 3, 0, 0, 0, 3, 0, 1, 4, 0, 1, 0, 0, 2, 0, 0, 4, 0, 4, 1, 0, 0, 0, 1, 0] },
    { label: 'Lactic Acid', result: [-1, 1, 1, 2, 3, 3, 1, 1, 4, 0, 4, 4, 3, 1, 2, 1, 1, 2, 3, 0, 2, 1, 1, 2, 2, 0, 1, 2, 1, 1, 1, 1, 0] },
    { label: 'Lard', result: [-1, 2, 1, 1, 1, 1, 0, 0, 1, 0, 1, 3, 0, 1, 0, 0, 0, 1, 1, 3, 0, 1, 0, 1, 1, 3, 2, 0, 4, 1, 1, 1, 0] },
    { label: 'Latex', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 2, 0, 0, 1, 1, 0, 3, 1, 0, 1, 0, 1, 0] },
    { label: 'Lead Acetate', result: [-1, 2, 1, 1, 0, 4, 1, 1, 3, 0, 0, 4, 0, 1, 2, 1, 1, 1, 1, 0, 2, 1, 0, 4, 2, 0, 4, 1, 1, 1, 1, 1, 0] },
    { label: 'Lead Sulfamate', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 2, 3, 1, 4, 3, 1, 0, 0, 0,] },
    { label: 'Ligroin', result: [-1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 4, 1, 0, 0, 0, 4, 0, 1, 1, 0, 2, 1, 4, 1, 0, 1, 0] },
    { label: 'Lime', result: [-1, 0, 1, 1, 0, 3, 1, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 4, 0, 3, 0, 0, 0, 1, 1, 3, 2, 4, 0, 1, 1, 1, 0] },
    { label: 'Lubricants', result: [-1, 0, 1, 1, 0, 1, 1, 1, 2, 0, 0, 0, 0, 1, 0, 1, 0, 1, 1, 2, 0, 1, 1, 1, 1, 3, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Magnesium Carbonate', result: [-1, 0, 1, 1, 1, 0, 0, 2, 0, 0, 0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 2, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0] },
    { label: 'Magnesium Chloride', result: [-1, 2, 2, 2, 1, 4, 1, 1, 2, 3, 4, 3, 0, 1, 2, 1, 1, 1, 1, 0, 2, 1, 1, 1, 1, 0, 1, 1, 1, 1, 0, 1, 0] },
    { label: 'Magnesium Hydroxide', result: [-1, 1, 1, 1, 0, 4, 1, 1, 3, 2, 2, 2, 1, 1, 0, 1, 1, 1, 1, 0, 2, 1, 1, 1, 2, 0, 2, 0, 3, 1, 1, 1, 0] },
    { label: 'Magnesium Nitrate', result: [-1, 0, 1, 1, 1, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Magnesium Oxide', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0] },
    { label: 'Magnesium Sulphate', result: [-1, 2, 2, 1, 0, 2, 1, 2, 2, 2, 3, 2, 0, 1, 2, 1, 1, 1, 1, 0, 2, 1, 1, 1, 1, 0, 1, 4, 3, 1, 1, 1, 0] },
    { label: 'Maleic Acid', result: [-1, 3, 1, 1, 1, 2, 1, 1, 3, 0, 0, 2, 0, 1, 2, 1, 1, 3, 1, 0, 0, 3, 0, 1, 4, 0, 1, 4, 4, 1, 1, 1, 0] },
    { label: 'Maleic Anhydride', result: [-1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 1, 4, 0, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Malic Acid', result: [-1, 2, 1, 1, 0, 3, 0, 1, 4, 0, 0, 4, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 0, 2, 0, 0, 1, 0, 1, 0, 0, 1, 0] },
    { label: 'Mash', result: [-1, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Mayonnaise', result: [-1, 1, 1, 1, 0, 4, 0, 0, 4, 0, 4, 4, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Melamine', result: [-1, 0, 4, 4, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Mercuric Chloride (Dilute Solution)', result: [-1, 4, 4, 4, 4, 4, 1, 2, 4, 4, 4, 4, 0, 1, 1, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Mercuric Cyanide', result: [-1, 1, 1, 1, 0, 4, 1, 0, 4, 0, 0, 4, 0, 1, 0, 1, 1, 1, 0, 0, 2, 1, 0, 0, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Mercury', result: [-1, 1, 1, 1, 1, 3, 3, 1, 4, 4, 1, 1, 0, 1, 0, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Methanol (See Alcohol Methyl)', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Methyl Acetate', result: [-1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 2, 0, 0, 0, 1, 0, 1, 0, 4, 0, 0, 0, 4, 4, 4, 2, 2, 4, 0, 1, 1, 0] },
    { label: 'Methyl Acrylate', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 4, 4, 0, 2, 2, 4, 1, 1, 1, 0] },
    { label: 'Methyl Acetone', result: [-1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 0, 0, 1, 4, 1, 0, 0, 0, 0, 0, 4, 4, 0, 4, 0, 0, 3, 0, 1, 0] },
    { label: 'Methyl Alcohol 10%', result: [-1, 1, 0, 1, 0, 3, 0, 1, 3, 0, 0, 2, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 2, 0, 0, 0, 1, 1, 0, 0, 0,] },
    { label: 'Methyl Bromide', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 4, 0, 0, 1, 2, 0, 4, 4, 4, 2, 1, 1, 0] },
    { label: 'Methyl Butyl Ketone', result: [-1, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 2, 0, 0, 0, 0, 0, 4, 4, 3, 4, 1, 4, 2, 1, 1, 0] },
    { label: 'Methyl Cellosolve', result: [-1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 3, 2, 0, 0, 0, 1, 0, 4, 4, 0, 4, 2, 4, 3, 1, 1, 0] },
    { label: 'Methyl Chloride', result: [-1, 0, 1, 1, 0, 4, 1, 1, 1, 0, 0, 0, 1, 4, 0, 1, 4, 1, 1, 0, 4, 4, 0, 1, 4, 4, 4, 3, 4, 1, 1, 1, 0] },
    { label: 'Methyl Dichloride', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 1, 0, 0, 0, 0, 0, 1, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Methyl Ethyl Ketone', result: [-1, 0, 1, 1, 0, 1, 1, 1, 1, 0, 0, 0, 4, 4, 0, 1, 4, 2, 1, 4, 4, 1, 1, 4, 4, 3, 4, 1, 4, 2, 1, 1, 0] },
    { label: 'Methyl Isobutyl Ketone', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 4, 4, 0, 1, 4, 2, 1, 4, 0, 3, 1, 4, 4, 3, 4, 3, 4, 2, 1, 1, 0] },
    { label: 'Methyl Isopropyl Ketone', result: [-1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 2, 1, 0, 0, 0, 0, 4, 4, 2, 4, 2, 4, 2, 1, 1, 0] },
    { label: 'Methyl Methacrylate', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 4, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Methylamine', result: [-1, 1, 0, 1, 0, 1, 0, 0, 4, 0, 2, 2, 0, 0, 0, 0, 2, 4, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Methylene Chloride', result: [-1, 1, 1, 1, 0, 1, 1, 1, 1, 3, 0, 2, 4, 4, 0, 1, 4, 1, 4, 0, 4, 4, 0, 4, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Milk', result: [-1, 1, 1, 1, 1, 1, 0, 0, 3, 3, 4, 4, 0, 1, 0, 0, 1, 1, 1, 2, 2, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Molasses', result: [-1, 1, 1, 1, 1, 1, 0, 0, 1, 2, 1, 1, 0, 1, 0, 0, 2, 1, 1, 0, 2, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 1] },
    { label: 'Mustard', result: [-1, 1, 1, 1, 1, 2, 0, 0, 2, 0, 3, 2, 0, 1, 0, 0, 2, 2, 1, 2, 0, 1, 0, 1, 2, 3, 3, 0, 0, 1, 1, 1, 0] },
    { label: 'Naphtha', result: [-1, 1, 1, 1, 1, 1, 1, 1, 2, 0, 2, 2, 1, 1, 3, 1, 4, 1, 1, 3, 4, 1, 1, 1, 2, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Naphthalene', result: [-1, 2, 1, 2, 0, 2, 1, 1, 3, 0, 2, 1, 1, 4, 0, 1, 4, 1, 0, 0, 4, 2, 1, 2, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Nickel Chloride', result: [-1, 0, 1, 2, 0, 4, 1, 1, 4, 0, 4, 0, 1, 1, 2, 1, 1, 2, 1, 0, 2, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Nickel Sulphate', result: [-1, 2, 1, 2, 0, 4, 1, 2, 3, 3, 4, 4, 1, 1, 1, 1, 1, 2, 1, 0, 2, 1, 0, 1, 1, 0, 1, 1, 3, 1, 1, 1, 0] },
    { label: 'Nitric Acid (10% Solution)', result: [-1, 1, 1, 1, 1, 4, 1, 1, 4, 0, 4, 4, 1, 1, 2, 1, 1, 4, 4, 3, 2, 1, 4, 1, 4, 0, 4, 2, 4, 1, 3, 2, 4] },
    { label: 'Nitric Acid (20% Solution)', result: [-1, 0, 1, 1, 1, 4, 1, 1, 4, 0, 4, 0, 2, 1, 2, 1, 1, 4, 4, 4, 2, 1, 3, 1, 4, 0, 4, 4, 4, 2, 4, 3, 4] },
    { label: 'Nitric Acid (50% Solution)', result: [-1, 0, 1, 1, 1, 4, 1, 1, 4, 0, 4, 0, 2, 1, 2, 1, 1, 4, 4, 4, 3, 4, 3, 1, 4, 0, 4, 4, 4, 4, 4, 1, 0] },
    { label: 'Nitric Acid (Concentrated Solution)', result: [-1, 0, 4, 2, 1, 2, 1, 2, 4, 4, 4, 0, 0, 4, 3, 1, 4, 4, 4, 4, 4, 4, 3, 2, 4, 0, 4, 4, 4, 4, 4, 1, 3] },
    { label: 'Nitrobenzene', result: [-1, 2, 1, 2, 0, 3, 1, 2, 4, 0, 2, 2, 4, 4, 4, 1, 4, 2, 3, 4, 4, 3, 2, 4, 4, 4, 4, 4, 4, 2, 1, 1, 0] },
    { label: 'Aniline Oil', result: [-1, 0, 1, 1, 0, 3, 1, 4, 1, 0, 1, 0, 0, 4, 0, 1, 4, 4, 3, 4, 0, 1, 0, 1, 4, 0, 4, 2, 4, 1, 1, 1, 0] },
    { label: 'Anise Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Bay Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Bone Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Castor Oil', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1, 2, 1, 1, 1, 1, 1] },
    { label: 'Cinnamon Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0, 4, 0, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Citric Oils', result: [-1, 0, 1, 1, 0, 0, 0, 0, 4, 0, 4, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Clove Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 2, 0, 0, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Coconut Oil', result: [-1, 0, 1, 1, 0, 2, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 4, 1, 1, 1, 0] },
    { label: 'Cod Liver Oil', result: [-1, 0, 1, 1, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 3, 0, 1, 0, 1, 1, 0, 2, 1, 4, 1, 1, 1, 0] },
    { label: 'Corn Oil', result: [-1, 0, 1, 1, 1, 2, 0, 0, 2, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 3, 0, 1, 0, 1, 1, 0, 4, 3, 4, 1, 1, 1, 0] },
    { label: 'Cotton Seed Oil', result: [-1, 2, 1, 1, 1, 2, 0, 0, 2, 0, 1, 3, 0, 1, 0, 1, 0, 1, 1, 3, 0, 1, 1, 1, 1, 0, 4, 3, 4, 1, 1, 1, 0] },
    { label: 'Creosote Oil', result: [-1, 0, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 4, 0, 1, 1, 0, 2, 4, 4, 1, 1, 1, 0] },
    { label: 'Diesel Fuel (2D,3D,4D,5D)', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 4, 1, 1, 0, 0, 1, 1, 1, 1, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Fuel (1,2,3,5A,5B,6) Oil', result: [-1, 0, 1, 1, 0, 1, 1, 1, 1, 0, 0, 0, 0, 1, 0, 1, 4, 1, 0, 0, 0, 2, 0, 1, 2, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Ginger Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Hydraulic (See Hydraulic) Oil', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Lemon Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 4, 0, 1, 0, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Linseed Oil', result: [-1, 0, 1, 1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 1, 2, 0, 0, 1, 1, 3, 0, 1, 0, 1, 1, 0, 4, 4, 4, 1, 1, 1, 1] },
    { label: 'Mineral Oil', result: [-1, 1, 1, 1, 1, 1, 0, 0, 1, 0, 1, 2, 0, 1, 0, 0, 2, 1, 1, 0, 0, 2, 1, 1, 1, 0, 2, 4, 4, 1, 1, 1, 1] },
    { label: 'Olive Oil', result: [-1, 1, 1, 1, 0, 1, 0, 0, 2, 0, 1, 2, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1, 1, 3, 2, 0, 4, 1, 1, 1, 0] },
    { label: 'Orange Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Palm Oil', result: [-1, 0, 1, 1, 0, 1, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Peanut Oil', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Peppermint Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 4, 0, 1, 4, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Pine Oil', result: [-1, 1, 1, 1, 0, 1, 0, 0, 4, 0, 3, 2, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Rape Seed Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 2, 0, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Rosin Oil', result: [-1, 0, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Sesame Seed Oil', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Silicone Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 1, 1, 1] },
    { label: 'Soybean Oil', result: [-1, 0, 1, 1, 0, 1, 0, 0, 2, 0, 1, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Sperm Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Tanning Oil', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 4, 0, 0, 1, 1, 1, 0] },
    { label: 'Turbine Oil', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 3, 0, 0, 0, 1, 1, 0, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Oleic Acid Oil', result: [-1, 2, 1, 1, 2, 2, 0, 2, 2, 3, 3, 3, 0, 1, 3, 1, 3, 2, 1, 2, 4, 3, 0, 4, 2, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Oleum 25% Oil', result: [-1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 2, 4, 0, 1, 4, 0, 0, 0, 0, 0, 0, 1, 4, 4, 4, 4, 0, 4, 0, 1, 0] },
    { label: 'Oleum Oil', result: [-1, 2, 0, 1, 0, 2, 0, 0, 3, 3, 0, 2, 4, 4, 0, 1, 0, 4, 0, 0, 0, 4, 0, 1, 3, 4, 4, 4, 4, 1, 0, 1, 0] },
    { label: 'Oxalic Acid (cold) Oil', result: [-1, 3, 1, 2, 1, 3, 3, 2, 2, 3, 4, 4, 0, 1, 2, 1, 3, 3, 4, 0, 1, 1, 0, 1, 2, 3, 2, 1, 3, 1, 1, 1, 0] },
    { label: 'Paraffin Oil', result: [-1, 1, 1, 1, 1, 1, 0, 0, 1, 0, 2, 2, 1, 1, 0, 1, 2, 1, 1, 2, 0, 1, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Pentane', result: [-1, 1, 3, 3, 0, 1, 0, 2, 1, 0, 2, 2, 0, 0, 0, 1, 4, 1, 1, 4, 0, 0, 0, 1, 1, 0, 2, 4, 4, 1, 1, 1, 0] },
    { label: 'Perchloroethylene', result: [-1, 2, 1, 1, 0, 1, 0, 0, 3, 0, 2, 2, 1, 0, 0, 1, 4, 1, 0, 4, 0, 4, 1, 1, 3, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Petrolatum', result: [-1, 1, 0, 1, 0, 2, 0, 0, 2, 0, 3, 3, 0, 0, 0, 1, 4, 1, 1, 2, 0, 0, 0, 1, 1, 0, 2, 1, 4, 1, 1, 1, 0] },
    { label: 'Phenol 10%', result: [-1, 2, 1, 1, 0, 1, 0, 2, 3, 0, 2, 4, 0, 1, 3, 1, 0, 0, 4, 0, 0, 0, 1, 2, 4, 0, 3, 4, 3, 3, 0, 0, 0,] },
    { label: 'Phenol (Carbolic Acid)', result: [-1, 2, 1, 1, 1, 2, 3, 1, 2, 4, 4, 4, 1, 1, 3, 1, 3, 4, 4, 0, 4, 2, 1, 1, 4, 0, 4, 4, 4, 2, 1, 4, 1] },
    { label: 'Phosphoric Acid (to 40% Solution)', result: [-1, 0, 2, 1, 1, 4, 1, 1, 4, 4, 4, 0, 0, 1, 2, 1, 1, 4, 4, 3, 2, 1, 1, 1, 4, 0, 4, 2, 3, 1, 2, 3, 4] },
    { label: 'Phosphoric Acid (40%-100% Solution)', result: [-1, 0, 3, 2, 2, 4, 2, 1, 4, 4, 4, 0, 0, 1, 2, 1, 1, 4, 4, 4, 3, 1, 1, 1, 4, 0, 4, 2, 3, 3, 2, 4, 4] },
    { label: 'Phosphoric Acid (Crude)', result: [-1, 0, 4, 3, 3, 4, 3, 1, 4, 4, 4, 4, 1, 0, 0, 1, 0, 4, 4, 4, 3, 0, 1, 1, 4, 0, 4, 2, 0, 1, 3, 4, 0,] },
    { label: 'Phosphoric Anhydride (Dry or Moist)', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 0, 0, 4, 4, 1, 0, 0, 0, 0, 0, 0, 0, 4, 4, 0, 4, 0, 1, 0, 1, 0, 0] },
    { label: 'Phosphoric Anhydride (Molten)', result: [-1, 0, 1, 1, 0, 4, 0, 0, 4, 4, 0, 0, 0, 4, 0, 1, 0, 0, 1, 0, 4, 0, 0, 4, 3, 0, 4, 0, 4, 1, 0, 0, 0,] },
    { label: 'Photographic (Developer)', result: [-1, 0, 3, 1, 3, 3, 1, 1, 0, 0, 4, 0, 0, 1, 0, 0, 1, 3, 0, 0, 2, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Phthalic Anhydride', result: [-1, 2, 1, 2, 0, 2, 0, 1, 2, 0, 3, 3, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 3, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Picric Acid', result: [-1, 2, 1, 1, 0, 3, 0, 1, 4, 4, 4, 4, 0, 1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 1, 1, 4, 1, 0, 1, 1, 0, 0, 0,] },
    { label: 'Plating  Solution Antimony Plating 130°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 4, 1, 0, 0, 2, 0, 1, 0] },
    { label: 'Plating Solutions Arsenic Plating 110°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 4, 1, 0, 0, 2, 0, 3, 0,] },
    { label: 'Brass Plating Regular Brass Bath 100°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 4, 1, 0, 0, 2, 0, 3, 0,] },
    { label: 'Brass Plating High Speed Brass Bath 110°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 4, 1, 0, 0, 2, 0, 4, 0,] },
    { label: 'Bronze Plating Copper-Cadmium Bronze Bath R.T.', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 4, 1, 0, 0, 2, 0, 3, 0,] },
    { label: 'Bronze Plating Copper-Tin Bronze Bath 160°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 4, 2, 0, 0, 3, 0, 4, 0,] },
    { label: 'Bronze Plating Copper-Zinc Bronze Bath 100°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 2, 0, 3, 0,] },
    { label: 'Cadmium Plating Cyanide Bath 90°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 2, 0, 3, 0,] },
    { label: 'Cadmium Plating Fluoborate Bath 100°F', result: [-1, 0, 0, 1, 0, 0, 4, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 2, 0, 4, 0,] },
    { label: 'Chromium Plating Chromic-Sulphuric Bath 130°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 4, 0, 4, 0, 0, 1, 0, 3, 4, 0, 4, 0, 0, 4, 0, 1, 0] },
    { label: 'Chromium Plating Fluosilicate Bath 95°F', result: [-1, 0, 0, 3, 0, 0, 3, 1, 0, 0, 0, 0, 0, 1, 0, 1, 4, 0, 4, 0, 0, 1, 0, 3, 4, 0, 4, 0, 4, 4, 0, 2, 0] },
    { label: 'Chromium Plating Fluoride Bath 130°F', result: [-1, 0, 0, 4, 0, 0, 3, 1, 0, 0, 0, 0, 0, 1, 0, 1, 4, 0, 4, 0, 0, 1, 0, 3, 4, 0, 4, 0, 0, 4, 0, 2, 0] },
    { label: 'Chromium Plating Black Chrome Bath 115°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 4, 0, 4, 0, 0, 1, 0, 3, 4, 0, 4, 0, 0, 4, 0, 1, 0] },
    { label: 'Chromium Plating Barrel Chrome Bath 95°F', result: [-1, 0, 0, 4, 0, 0, 3, 1, 0, 0, 0, 0, 0, 1, 0, 1, 4, 0, 4, 0, 0, 1, 0, 3, 4, 0, 4, 0, 0, 4, 0, 1, 0] },
    { label: 'Copper Plating (Cyanide) Copper Strike Bath 120°F', result: [-1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 2, 0, 0, 1, 0, 0, 0, 0, 3, 0,] },
    { label: 'Copper Plating (Cyanide) Rochelle Salt Bath 150°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 2, 0, 0, 3, 0, 4, 0,] },
    { label: 'Copper Plating (Cyanide) High Speed Bath 180°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 2, 0, 0, 3, 0, 4, 0,] },
    { label: 'Copper Plating (Acid) Copper Sulphate Bath R.T.', result: [-1, 0, 0, 4, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 4, 0, 4, 0,] },
    { label: 'Copper Plating (Acid) Copper Fluoborate Bath 120°F', result: [-1, 0, 0, 4, 0, 0, 4, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 4, 0, 4, 0,] },
    { label: 'Copper (Misc.) Copper Pyrophosphate 140°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 2, 0, 2, 0] },
    { label: 'Copper (Misc.) Copper (Electrolysis) 140°F', result: [-1, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 4, 0, 4, 0, 0, 2, 0, 4, 0,] },
    { label: 'Gold Plating Cyanide 150°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 3, 0, 0, 0, 0, 4, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 4, 0, 2, 0] },
    { label: 'Gold Plating Neutral 75°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Gold Plating Acid 75°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Indium Sulfamate Plating R.T.', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Iron Plating Ferrous Chloride Bath 190°F', result: [-1, 0, 0, 4, 0, 0, 1, 4, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 0, 3, 0, 1, 2, 0, 4, 0, 0, 4, 0, 1, 0] },
    { label: 'Iron Plating Ferrous Sulphate Bath 150°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 0, 2, 0, 0, 4, 0, 1, 0] },
    { label: 'Iron Plating Ferrous Am. Sulphate Bath 150°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 0, 2, 0, 0, 4, 0, 1, 0] },
    { label: 'Iron Plating Sulphate0Chloride Bath 160°F', result: [-1, 0, 0, 4, 0, 0, 1, 4, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 4, 0, 1, 0] },
    { label: 'Iron Plating Fluoborate Bath 145°F', result: [-1, 0, 0, 4, 0, 0, 4, 2, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 4, 0, 4, 0,] },
    { label: 'Iron Plating Sulfamate 140°F', result: [-1, 0, 0, 4, 0, 0, 1, 2, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Lead Fluoborate Plating', result: [-1, 0, 0, 3, 0, 0, 4, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 1, 0, 4, 0,] },
    { label: 'Nickel Plating Watts Type 115-160°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 4, 0, 1, 0] },
    { label: 'Nickel Plating High Chloride 130-160°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 0, 2, 0, 0, 4, 0, 1, 0] },
    { label: 'Nickel Plating Fluoborate 100-170°F', result: [-1, 0, 0, 3, 0, 0, 4, 1, 4, 0, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 4, 0, 4, 0,] },
    { label: 'Nickel Plating Sulfamate 100-140°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Nickel Plating Electrolysis 200°F', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 1, 4, 0, 4, 0, 0, 4, 0, 1, 4, 0, 4, 0, 0, 2, 0, 1, 0] },
    { label: 'Rhodium Plating 120°F', result: [-1, 0, 0, 4, 0, 0, 4, 4, 0, 0, 0, 0, 0, 1, 0, 1, 1, 4, 4, 0, 0, 1, 0, 1, 1, 0, 2, 0, 0, 1, 0, 1, 0] },
    { label: 'Silver Plating 80-120°F', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 2, 0] },
    { label: 'Tin0Fluoborate Plating 100°F', result: [-1, 0, 0, 3, 0, 0, 4, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 1, 0, 4, 0,] },
    { label: 'Tin0Lead Plating 100°F', result: [-1, 0, 0, 3, 0, 0, 4, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 1, 0, 4, 0,] },
    { label: 'Zinc Plating', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Zinc Plating Acid Chloride 140°F', result: [-1, 0, 0, 4, 0, 0, 1, 4, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Zinc Plating Acid Sulphate Bath 150°F', result: [-1, 0, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 1, 0, 2, 0, 0, 4, 0, 1, 0] },
    { label: 'Zinc Plating Acid Fluoborate Bath R.T.', result: [-1, 0, 0, 0, 3, 0, 4, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 4, 0, 0, 1, 0, 1, 2, 0, 3, 0, 0, 1, 0, 4, 0,] },
    { label: 'Zinc Plating Alkaline Cyanide Bath R.T.', result: [-1, 0, 0, 0, 1, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 4, 0,] },
    { label: 'Potash', result: [-1, 0, 1, 0, 1, 3, 0, 1, 3, 0, 2, 0, 0, 1, 2, 0, 1, 2, 1, 0, 2, 1, 0, 1, 1, 0, 2, 0, 2, 1, 1, 1, 1] },
    { label: 'Potassium Bicarbonate', result: [-1, 0, 1, 0, 2, 3, 1, 2, 2, 0, 4, 0, 1, 1, 0, 1, 1, 3, 1, 3, 2, 1, 1, 1, 1, 0, 1, 0, 2, 1, 1, 1, 0] },
    { label: 'Potassium Bromide', result: [-1, 1, 1, 0, 2, 3, 1, 2, 3, 0, 4, 4, 1, 1, 0, 1, 1, 1, 3, 0, 2, 1, 3, 1, 1, 0, 1, 1, 2, 1, 1, 1, 0] },
    { label: 'Potassium Carbonate', result: [-1, 2, 1, 0, 1, 3, 1, 1, 3, 0, 2, 2, 1, 1, 2, 1, 1, 2, 1, 0, 2, 1, 1, 1, 2, 0, 1, 0, 2, 1, 1, 1, 1] },
    { label: 'Potassium Chlorate', result: [-1, 2, 1, 1, 1, 2, 1, 2, 2, 0, 2, 2, 1, 1, 2, 1, 1, 2, 4, 0, 2, 1, 1, 1, 1, 0, 1, 0, 2, 1, 1, 1, 0] },
    { label: 'Potassium Chloride', result: [-1, 3, 1, 1, 2, 2, 1, 1, 3, 3, 2, 2, 1, 1, 1, 1, 1, 1, 2, 3, 2, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Potassium Chromate', result: [-1, 0, 0, 2, 2, 1, 0, 2, 1, 0, 1, 0, 0, 1, 0, 0, 1, 3, 0, 0, 2, 0, 1, 1, 1, 0, 1, 0, 2, 3, 1, 4, 0,] },
    { label: 'Potassium Cyanide Solutions', result: [-1, 2, 1, 2, 1, 4, 1, 1, 4, 0, 2, 2, 1, 1, 0, 1, 1, 3, 1, 0, 2, 1, 1, 2, 1, 0, 1, 1, 1, 1, 3, 1, 0] },
    { label: 'Potassium Dichromate', result: [-1, 2, 1, 1, 1, 1, 1, 2, 3, 0, 2, 3, 1, 1, 0, 1, 1, 3, 4, 0, 2, 1, 1, 2, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Potassium Ferrocyanide', result: [-1, 2, 1, 0, 1, 3, 0, 2, 1, 0, 0, 3, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 0, 4, 0, 0, 0, 1, 1, 0, 0, 0,] },
    { label: 'Potassium Hydroxide (50%)', result: [-1, 1, 2, 2, 2, 4, 3, 1, 4, 4, 3, 1, 4, 1, 2, 1, 1, 4, 1, 3, 2, 1, 1, 4, 2, 3, 1, 1, 3, 1, 0, 4, 1] },
    { label: 'Potassium Nitrate', result: [-1, 2, 1, 2, 1, 2, 1, 2, 2, 0, 0, 2, 1, 1, 3, 1, 1, 2, 3, 0, 2, 1, 3, 2, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Potassium Permanganate', result: [-1, 2, 1, 2, 2, 2, 2, 2, 2, 0, 2, 2, 1, 1, 0, 1, 1, 3, 4, 3, 2, 2, 1, 2, 1, 0, 1, 0, 2, 2, 1, 1, 0] },
    { label: 'Potassium Sulphate', result: [-1, 2, 1, 2, 2, 1, 1, 1, 2, 2, 2, 2, 1, 1, 1, 1, 1, 2, 3, 0, 2, 1, 1, 1, 1, 3, 1, 1, 3, 1, 1, 1, 0] },
    { label: 'Potassium Sulphide', result: [-1, 1, 1, 0, 1, 2, 0, 2, 2, 0, 2, 2, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Propane (Liquified)', result: [-1, 1, 1, 0, 1, 1, 0, 0, 1, 1, 0, 2, 0, 4, 0, 1, 4, 1, 1, 0, 0, 4, 0, 1, 1, 4, 2, 4, 4, 1, 1, 1, 0] },
    { label: 'Propylene Glycol', result: [-1, 2, 2, 0, 1, 1, 0, 0, 2, 0, 2, 2, 0, 0, 0, 1, 0, 2, 2, 2, 2, 0, 0, 1, 1, 0, 3, 0, 0, 1, 1, 1, 0] },
    { label: 'Pyridine', result: [-1, 0, 3, 0, 2, 2, 0, 0, 0, 0, 2, 1, 4, 0, 4, 1, 4, 4, 0, 0, 3, 2, 1, 4, 4, 0, 4, 2, 4, 1, 1, 1, 0] },
    { label: 'Pyrogallic Acid', result: [-1, 2, 1, 1, 1, 2, 0, 1, 2, 0, 2, 2, 0, 1, 0, 1, 0, 4, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Rosins', result: [-1, 1, 1, 1, 1, 1, 0, 2, 1, 3, 0, 3, 0, 0, 0, 1, 0, 2, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Rum', result: [-1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Rust Inhibitors', result: [-1, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 1, 0, 3, 0, 0, 1, 1, 1, 0] },
    { label: 'Salad Dressing', result: [-1, 0, 1, 0, 1, 2, 0, 0, 2, 0, 4, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Sea Water', result: [-1, 1, 1, 3, 1, 3, 1, 0, 3, 0, 0, 4, 0, 1, 0, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 2, 2, 1, 1, 1, 1, 1, 1] },
    { label: 'Shellac (Bleached)', result: [-1, 1, 1, 0, 1, 1, 0, 0, 1, 2, 2, 1, 0, 0, 0, 1, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1, 0] },
    { label: 'Shellac (Orange)', result: [-1, 1, 1, 0, 1, 1, 0, 0, 1, 3, 3, 1, 0, 0, 0, 1, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1, 0] },
    { label: 'Silicone', result: [-1, 0, 2, 0, 1, 2, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Silver Bromide', result: [-1, 0, 3, 3, 2, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0] },
    { label: 'Silver Nitrate', result: [-1, 2, 1, 2, 1, 4, 1, 1, 4, 0, 4, 4, 1, 1, 2, 1, 1, 3, 1, 0, 2, 1, 0, 1, 3, 0, 1, 3, 1, 1, 1, 1, 0] },
    { label: 'Soap Solutions', result: [-1, 1, 1, 1, 1, 3, 1, 2, 2, 0, 2, 1, 0, 2, 2, 1, 1, 1, 1, 0, 2, 1, 1, 1, 1, 2, 2, 0, 3, 1, 1, 1, 1] },
    { label: 'Soda Ash ( See Sodium Carbonate)', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,] },
    { label: 'Sodium Acetate', result: [-1, 2, 1, 1, 2, 2, 1, 0, 2, 0, 3, 3, 1, 1, 0, 1, 1, 2, 1, 0, 2, 1, 0, 4, 4, 0, 3, 0, 1, 1, 1, 1, 0] },
    { label: 'Sodium Aluminate', result: [-1, 2, 0, 0, 1, 3, 2, 2, 2, 0, 0, 3, 0, 0, 0, 1, 1, 2, 1, 0, 0, 0, 1, 1, 1, 0, 1, 1, 2, 1, 1, 1, 0] },
    { label: 'Sodium Bicarbonate', result: [-1, 2, 1, 1, 1, 1, 1, 0, 2, 1, 3, 3, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 1, 1, 1, 3, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Sodium Bisulphate', result: [-1, 1, 1, 0, 1, 4, 2, 2, 3, 3, 4, 4, 1, 1, 2, 1, 1, 2, 3, 3, 2, 1, 1, 2, 1, 3, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Sodium Bisulphite', result: [-1, 0, 1, 0, 1, 1, 1, 2, 3, 0, 4, 0, 1, 1, 2, 1, 1, 2, 4, 2, 2, 1, 1, 1, 1, 3, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Sodium Borate', result: [-1, 2, 1, 0, 1, 3, 0, 1, 1, 0, 3, 3, 0, 3, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 2, 1, 0, 0, 0, 0, 0, 0,] },
    { label: 'Sodium Carbonate', result: [-1, 2, 1, 2, 2, 3, 1, 1, 2, 2, 2, 2, 1, 1, 2, 1, 1, 1, 1, 3, 2, 1, 1, 1, 1, 0, 1, 1, 1, 1, 2, 1, 0] },
    { label: 'Sodium Chlorate', result: [-1, 2, 1, 0, 1, 2, 1, 2, 2, 0, 0, 3, 1, 1, 2, 1, 1, 4, 1, 0, 2, 1, 1, 1, 4, 0, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Sodium Chloride', result: [-1, 2, 1, 3, 2, 3, 1, 1, 2, 3, 2, 3, 1, 1, 2, 1, 1, 1, 1, 2, 2, 1, 1, 1, 1, 3, 1, 1, 2, 1, 1, 1, 1] },
    { label: 'Sodium Chromate', result: [-1, 1, 1, 1, 0, 4, 0, 2, 2, 0, 2, 2, 0, 0, 0, 1, 1, 4, 1, 0, 0, 1, 1, 2, 1, 0, 1, 0, 0, 3, 1, 2, 0] },
    { label: 'Sodium Cyanide', result: [-1, 2, 1, 0, 1, 4, 1, 0, 4, 4, 2, 2, 1, 1, 0, 1, 1, 4, 3, 0, 2, 1, 1, 1, 1, 4, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Sodium Fluoride', result: [-1, 2, 3, 0, 3, 3, 1, 1, 3, 0, 4, 4, 0, 4, 4, 1, 0, 0, 1, 0, 3, 0, 0, 2, 4, 0, 4, 0, 4, 1, 0, 0, 0,] },
    { label: 'Sodium Hydrosulphite', result: [-1, 0, 0, 0, 0, 1, 0, 1, 3, 0, 0, 0, 0, 3, 1, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0] },
    { label: 'Sodium Hydroxide (20%)', result: [-1, 0, 1, 1, 1, 4, 1, 1, 3, 4, 1, 0, 1, 1, 2, 1, 1, 4, 3, 3, 2, 1, 1, 1, 1, 4, 2, 1, 1, 1, 3, 4, 1] },
    { label: 'Sodium Hydroxide (50% Solution)', result: [-1, 0, 1, 2, 0, 4, 1, 1, 3, 4, 2, 0, 4, 1, 2, 1, 1, 4, 3, 3, 3, 1, 2, 4, 4, 4, 3, 0, 1, 1, 3, 4, 1] },
    { label: 'Sodium Hydroxide (80% Solution)', result: [-1, 0, 1, 4, 0, 4, 1, 2, 3, 4, 3, 0, 0, 1, 2, 1, 1, 4, 3, 3, 3, 1, 2, 2, 4, 4, 3, 0, 2, 1, 3, 4, 1] },
    { label: 'Sodium Hypochlorite/Bleach (to 20%)', result: [-1, 0, 3, 3, 3, 3, 1, 1, 4, 4, 4, 0, 0, 1, 2, 1, 1, 4, 1, 0, 2, 3, 3, 1, 3, 4, 4, 2, 3, 2, 4, 1, 2] },
    { label: 'Sodium Hypochlorite/Bleach', result: [-1, 4, 0, 4, 0, 4, 1, 1, 4, 0, 4, 4, 1, 1, 0, 1, 1, 0, 1, 0, 0, 3, 3, 2, 2, 3, 1, 0, 0, 1, 0, 4, 0,] },
    { label: 'Sodium Hyposulphfate', result: [-1, 0, 1, 1, 0, 4, 0, 0, 4, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 3, 3, 0, 0, 0,] },
    { label: 'Sodium Metaphosphate', result: [-1, 1, 0, 1, 0, 1, 0, 0, 3, 3, 2, 2, 0, 0, 0, 1, 0, 2, 1, 0, 0, 4, 0, 1, 1, 0, 2, 1, 1, 1, 1, 1, 0] },
    { label: 'Sodium Metasilicate', result: [-1, 1, 0, 1, 0, 2, 0, 0, 2, 0, 3, 3, 0, 0, 0, 1, 0, 4, 0, 0, 0, 0, 0, 1, 1, 4, 1, 0, 0, 1, 1, 0, 0] },
    { label: 'Sodium Nitrate', result: [-1, 2, 1, 1, 1, 1, 1, 2, 2, 3, 1, 2, 1, 1, 2, 1, 1, 2, 1, 0, 2, 1, 0, 4, 3, 4, 2, 1, 3, 1, 1, 1, 1] },
    { label: 'Sodium Perborate', result: [-1, 2, 0, 3, 0, 2, 0, 0, 3, 3, 2, 2, 0, 0, 0, 1, 1, 2, 1, 0, 0, 1, 0, 1, 2, 4, 2, 1, 3, 1, 1, 1, 0] },
    { label: 'Sodium Peroxide', result: [-1, 2, 1, 1, 0, 3, 0, 2, 3, 3, 4, 3, 0, 1, 0, 1, 0, 4, 4, 0, 0, 0, 0, 1, 3, 4, 2, 1, 3, 1, 1, 1, 0] },
    { label: 'Sodium Polyphosphate (Mono, Di , Tribasic)', result: [-1, 0, 1, 1, 0, 4, 1, 1, 3, 0, 0, 0, 0, 0, 0, 1, 1, 2, 0, 0, 0, 0, 0, 1, 1, 0, 4, 1, 1, 1, 1, 1, 0] },
    { label: 'Sodium Silicate', result: [-1, 2, 1, 2, 1, 3, 1, 2, 3, 3, 0, 2, 0, 1, 2, 1, 1, 3, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Sodium Sulphate', result: [-1, 2, 1, 1, 3, 2, 1, 2, 2, 2, 1, 2, 0, 1, 0, 1, 1, 2, 1, 0, 2, 1, 1, 1, 1, 0, 1, 1, 3, 1, 1, 1, 0] },
    { label: 'Sodium Sulphide', result: [-1, 2, 1, 2, 0, 4, 1, 2, 4, 4, 1, 2, 0, 1, 2, 1, 1, 2, 1, 0, 2, 1, 1, 1, 3, 0, 1, 1, 3, 1, 1, 1, 0] },
    { label: 'Sodium Sulphite', result: [-1, 0, 3, 3, 0, 3, 1, 1, 3, 0, 1, 0, 0, 1, 1, 1, 0, 0, 4, 0, 1, 0, 0, 1, 1, 0, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Sodium Tetraborate', result: [-1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 2, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Sodium Thiosulphate (Hypo)', result: [-1, 1, 1, 1, 0, 2, 1, 0, 4, 4, 3, 2, 0, 1, 0, 1, 1, 3, 1, 0, 0, 1, 1, 1, 2, 0, 1, 1, 3, 1, 1, 1, 0] },
    { label: 'Sorghum', result: [-1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Soy Sauce', result: [-1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 4, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 1, 0, 1, 0, 4, 1, 1, 1, 0] },
    { label: 'Stannic Chloride', result: [-1, 4, 4, 4, 0, 4, 1, 2, 4, 0, 4, 4, 1, 1, 0, 1, 1, 3, 1, 0, 2, 1, 0, 1, 1, 4, 1, 1, 1, 1, 0, 1, 0] },
    { label: 'Stannic Fluoborate', result: [-1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 1, 3, 0, 0, 0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0] },
    { label: 'Stannous Chloride', result: [-1, 4, 4, 3, 0, 4, 1, 1, 4, 0, 4, 4, 0, 1, 1, 1, 0, 0, 4, 0, 1, 0, 0, 2, 3, 4, 4, 0, 1, 1, 0, 0, 0,] },
    { label: 'Starch', result: [-1, 2, 1, 1, 0, 1, 0, 0, 2, 0, 3, 3, 0, 1, 0, 1, 1, 1, 1, 0, 2, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Stearic Acid', result: [-1, 2, 1, 1, 1, 2, 1, 1, 3, 3, 3, 3, 1, 1, 2, 1, 1, 1, 1, 0, 2, 4, 0, 1, 2, 4, 2, 2, 3, 1, 1, 1, 1] },
    { label: 'Stoddard Solvent', result: [-1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 1, 1, 4, 1, 4, 1, 1, 2, 4, 4, 1, 1, 2, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Styrene', result: [-1, 1, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 2, 4, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Sugar (Liquids)', result: [-1, 1, 1, 1, 1, 1, 0, 1, 1, 0, 2, 2, 0, 0, 0, 1, 1, 1, 1, 2, 0, 1, 0, 1, 1, 0, 2, 0, 1, 1, 1, 1, 1] },
    { label: 'Sulphate Liquors', result: [-1, 0, 3, 3, 0, 2, 0, 1, 3, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 0, 1, 0, 0, 0, 0, 3, 0, 0, 1, 1, 1, 0] },
    { label: 'Sulphur Chloride', result: [-1, 0, 4, 4, 4, 4, 0, 0, 3, 4, 0, 0, 0, 1, 3, 1, 1, 4, 1, 0, 1, 4, 0, 1, 4, 0, 4, 4, 4, 3, 1, 3, 0,] },
    { label: 'Sulphur Dioxide', result: [-1, 0, 1, 1, 3, 1, 1, 2, 2, 0, 0, 0, 2, 4, 2, 1, 4, 2, 4, 4, 3, 4, 1, 4, 4, 3, 2, 1, 4, 1, 1, 1, 0] },
    { label: 'Sulphur Dioxide (Dry)', result: [-1, 1, 1, 1, 0, 1, 0, 1, 1, 3, 1, 2, 0, 4, 0, 1, 0, 0, 1, 0, 4, 0, 0, 4, 0, 0, 4, 0, 4, 4, 1, 1, 0] },
    { label: 'Sulphur Trioxide (Dry)', result: [-1, 1, 1, 3, 0, 1, 0, 0, 2, 0, 2, 2, 0, 1, 2, 1, 4, 4, 4, 0, 0, 0, 0, 1, 4, 0, 4, 2, 3, 1, 2, 1, 0] },
    { label: 'Sulphuric Acid (to 10%)', result: [-1, 0, 4, 3, 3, 3, 1, 1, 4, 4, 4, 0, 1, 1, 2, 1, 1, 4, 4, 2, 2, 1, 1, 1, 3, 0, 4, 4, 3, 1, 1, 1, 0] },
    { label: 'Sulphuric Acid (10%075%)', result: [-1, 0, 4, 4, 4, 4, 3, 2, 4, 4, 4, 0, 1, 1, 2, 1, 2, 4, 4, 2, 3, 1, 2, 1, 4, 0, 4, 4, 4, 2, 1, 1, 3] },
    { label: 'Sulphuric Acid 75%0100%', result: [-1, 0, 0, 4, 0, 0, 4, 2, 0, 4, 0, 0, 1, 2, 0, 1, 1, 0, 4, 0, 0, 2, 3, 1, 4, 0, 4, 0, 0, 4, 0, 1, 0] },
    { label: 'Sulphurous Acid', result: [-1, 3, 3, 2, 3, 3, 1, 2, 4, 0, 4, 4, 0, 1, 2, 1, 1, 4, 4, 0, 2, 1, 0, 1, 3, 4, 2, 2, 3, 1, 2, 1, 0] },
    { label: 'Sulphuryl Chloride', result: [-1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0] },
    { label: 'Syrup', result: [-1, 0, 1, 1, 1, 1, 0, 0, 4, 0, 0, 0, 0, 1, 0, 0, 1, 1, 1, 2, 0, 1, 0, 1, 1, 0, 2, 0, 1, 1, 1, 1, 1] },
    { label: 'Tallow', result: [-1, 0, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 3, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Tannic Acid', result: [-1, 2, 1, 1, 1, 3, 1, 2, 2, 0, 3, 3, 1, 1, 2, 1, 1, 2, 4, 0, 2, 1, 0, 1, 4, 3, 1, 1, 1, 1, 1, 1, 1] },
    { label: 'Tanning Liquors', result: [-1, 0, 1, 1, 0, 3, 1, 1, 1, 0, 0, 0, 0, 1, 2, 1, 0, 2, 0, 0, 0, 1, 0, 1, 3, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Tartaric Acid', result: [-1, 2, 1, 2, 2, 3, 1, 2, 1, 3, 4, 4, 1, 1, 2, 1, 1, 2, 1, 0, 2, 1, 0, 1, 4, 3, 1, 0, 1, 1, 1, 1, 0] },
    { label: 'Tetrachlorethane', result: [-1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 4, 0, 1, 4, 1, 1, 0, 0, 1, 0, 1, 4, 0, 0, 4, 4, 1, 1, 1, 0] },
    { label: 'Tetrahydrofuran', result: [-1, 0, 1, 1, 0, 4, 0, 0, 4, 0, 4, 1, 4, 4, 0, 1, 4, 1, 1, 0, 4, 3, 1, 4, 4, 0, 4, 2, 4, 1, 1, 1, 0] },
    { label: 'Toluene, Toluol', result: [-1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 4, 4, 1, 4, 1, 1, 4, 4, 4, 1, 3, 4, 4, 4, 4, 4, 1, 1, 1, 1] },
    { label: 'Tomato Juice', result: [-1, 1, 1, 1, 0, 1, 0, 0, 3, 0, 3, 3, 0, 0, 0, 1, 1, 2, 1, 2, 0, 1, 1, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Trichlorethane', result: [-1, 0, 3, 1, 0, 3, 1, 1, 3, 0, 3, 0, 0, 0, 0, 1, 4, 1, 0, 0, 0, 0, 0, 1, 4, 4, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Trichloroethylene', result: [-1, 2, 1, 1, 0, 2, 1, 1, 2, 1, 3, 2, 1, 4, 0, 1, 4, 1, 3, 4, 4, 4, 3, 1, 4, 4, 4, 4, 4, 1, 1, 1, 3] },
    { label: 'Trichloropropane', result: [-1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 4, 1, 0, 4, 0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Tricresylphosphate', result: [-1, 0, 0, 1, 0, 0, 2, 1, 1, 0, 0, 0, 0, 4, 0, 1, 3, 3, 0, 0, 0, 0, 0, 2, 4, 0, 4, 1, 0, 1, 1, 1, 0] },
    { label: 'Triethylamine', result: [-1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2, 4, 0, 0, 0, 0, 0, 1, 1, 4, 2, 0, 0, 1, 1, 1, 0] },
    { label: 'Turpentine', result: [-1, 2, 1, 1, 0, 3, 0, 1, 2, 3, 2, 2, 1, 1, 2, 1, 4, 1, 1, 0, 4, 2, 1, 1, 4, 0, 4, 4, 4, 1, 1, 1, 0] },
    { label: 'Urine', result: [-1, 0, 1, 1, 0, 2, 0, 0, 3, 0, 2, 0, 0, 1, 0, 0, 1, 1, 1, 0, 2, 1, 0, 1, 1, 0, 4, 1, 0, 1, 1, 1, 0] },
    { label: 'Vegetable Juice', result: [-1, 0, 1, 1, 0, 1, 0, 0, 3, 0, 4, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 1, 2, 4, 0, 4, 1, 1, 1, 0] },
    { label: 'Vinegar', result: [-1, 1, 1, 1, 1, 4, 1, 1, 2, 2, 3, 4, 1, 1, 0, 1, 1, 2, 1, 2, 2, 1, 1, 1, 3, 0, 2, 1, 3, 1, 1, 1, 1] },
    { label: 'Varnish (Use Viton® for Aromatic)', result: [-1, 1, 1, 1, 1, 1, 0, 0, 1, 2, 0, 3, 0, 0, 0, 1, 4, 1, 1, 0, 0, 1, 0, 1, 2, 3, 4, 0, 4, 1, 1, 1, 1] },
    { label: 'Water, Acid , Mine', result: [-1, 0, 1, 1, 0, 3, 0, 0, 3, 4, 3, 0, 0, 1, 2, 0, 1, 4, 1, 2, 0, 1, 2, 1, 1, 0, 2, 0, 2, 1, 1, 1, 0] },
    { label: 'Water, Distilled , Lab Grade 7', result: [-1, 0, 1, 1, 0, 2, 0, 0, 1, 0, 4, 0, 0, 1, 2, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 0, 2, 1, 1, 1, 1, 1, 1] },
    { label: 'Water, Fresh', result: [-1, 1, 1, 1, 0, 1, 0, 0, 1, 3, 2, 4, 0, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 2, 1, 1, 1, 1, 1, 1] },
    { label: 'Water, Salt', result: [-1, 0, 1, 1, 0, 2, 0, 0, 2, 3, 4, 0, 0, 1, 2, 0, 1, 1, 1, 0, 0, 1, 1, 1, 1, 0, 2, 1, 1, 1, 1, 1, 1] },
    { label: 'Weed Killers', result: [-1, 0, 1, 1, 0, 3, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 2, 0, 3, 0, 0, 1, 1, 1, 0] },
    { label: 'Whey', result: [-1, 0, 1, 1, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 1, 0] },
    { label: 'Whiskey and Wines', result: [-1, 1, 1, 1, 1, 4, 0, 0, 2, 2, 4, 4, 0, 1, 0, 1, 1, 1, 1, 0, 2, 1, 0, 1, 1, 2, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'White Liquor (Pulp Mill)', result: [-1, 0, 1, 1, 0, 0, 0, 1, 4, 0, 3, 0, 0, 1, 0, 1, 1, 4, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'White Water (Paper Mill)', result: [-1, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 2, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0] },
    { label: 'Xylene', result: [-1, 1, 1, 1, 0, 1, 0, 1, 1, 1, 1, 2, 1, 4, 0, 1, 4, 1, 1, 4, 4, 4, 1, 1, 4, 4, 4, 4, 4, 1, 1, 1, 1] },
    { label: 'Zinc Chloride', result: [-1, 4, 4, 2, 2, 4, 1, 2, 4, 4, 4, 4, 1, 1, 0, 1, 1, 3, 1, 0, 2, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0] },
    { label: 'Zinc Hydrosulphite', result: [-1, 0, 0, 1, 0, 4, 0, 0, 4, 0, 4, 0, 0, 0, 0, 0, 1, 3, 0, 0, 0, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 1, 0] },
    { label: 'Zinc Sulphate', result: [-1, 2, 1, 1, 1, 4, 1, 2, 2, 3, 3, 4, 1, 3, 2, 1, 1, 3, 1, 0, 2, 1, 1, 1, 1, 0, 1, 1, 3, 1, 1, 1, 0,] }
]