import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LeftNavBar } from '../components/leftNavBar';
import { formDataTypes, FormDataGeneric, FormRequirementAlert } from '../components/formbuilder';
import { FormPage } from '../components/formpage';
import { TermsAndConditions } from '../components/termsAndConditions';

const contactusForm = [
    { label: 'Full Name', keyName: 'fullname', type: formDataTypes.text, required: false },
    { label: 'Company Name', keyName: 'companyName', type: formDataTypes.text, required: true },
    { label: 'Address', keyName: 'address', type: formDataTypes.text, required: false },
    { label: 'Email Address', keyName: 'email', type: formDataTypes.email, required: true },
    { label: 'Phone Number', keyName: 'phone', type: formDataTypes.text, required: true },
    { label: 'Other Details', keyName: 'other', type: formDataTypes.textArea, required: false, as: formDataTypes.textArea, row: 4 },
]

export const ContactUs = () => {

    return (
        <Row>
            <LeftNavBar />
            <Col style={{paddingBottom: 30 }}>
                <p style={{ paddingTop: 15 }}>
                    <strong>Address:</strong>
                    <br />11 Flaggy Creek Road
                    <br />MOUNT CROSBY Qld 4306
                    <br /><strong>Postal Address:</strong>
                    <br />PO Box 7071
                    <br />MOUNT CROSBY Qld 4306
                    <br /><br /><strong>Mobile </strong><a href="tel:0437734000">0437 734 000</a>
                    <br /><strong>International </strong><a href="tel:+61437734000">+61 437 734 000</a>
                </p>
                <TermsAndConditions />
                {
                    <FormPage formTitle='Contact Us' formInput={contactusForm} formComponent={<ContactUsForm />} />
                }
            </Col>
        </Row>

    )
}

const ContactUsForm = () => {
    return (
        <Fragment>
            <p><em><strong>Please fill in the form below if you have any enquiries:</strong></em></p>
            <FormRequirementAlert />
            {
                contactusForm.map((f, k) => <FormDataGeneric key={k} {...f} />)
            }
        </Fragment>
    )
}


